import { TextField } from "@mui/material";
import {
  getNumberHint,
  getTextHint,
  getTranslationColumnGrid,
  getTranslationLabel,
  onValueChangeLengthValues,
  onValueChangeLengthValuesText,
} from "_utils/commonFunctions";
import { FieldArray } from "formik";
import { get } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setFieldSelectedLanguage } from "_store/common.slice";

const FieldItemBox = ({
  key,
  index,
  valueKey,
  translateKey,
  targetValueMapping,
  name,
  values,
  unitBox,
  inputType,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  setErrors,
  targetItem,
  subindex,
  unitName,
  value,
  disabled,
}) => {
  const dataProps = {};
  if (inputType === "number") {
    if (targetItem?.min) {
      dataProps["min"] = targetItem?.min;
    }
    if (targetItem?.max) {
      dataProps["max"] = targetItem?.max;
    }
  }
const fieldName = `${targetValueMapping}[${String(subindex)}].${String(name)}`;
  return (
    <div
      key={key}
      className={`${getTranslationColumnGrid()} ${unitBox ? "value-box" : ""}`}
      style={{ marginBottom: 8 }}
    >
      <div className="w-100">
        {
          <TextField
            id={fieldName}
            label={getTranslationLabel(value.translateId)}
            name={fieldName}
            value={
              get(
                values,
                `${targetValueMapping}[${String(subindex)}].${String(name)}`
              ) || ""
            }
            margin="normal"
            type={inputType}
            fullWidth
            disabled={disabled}
            error={Boolean(
              get(errors, `${targetValueMapping}[${String(subindex)}]`) &&
                get(touched, `${targetValueMapping}[${String(subindex)}]`)
                  .length > 0
            )}
            // InputProps={{
            //   inputProps: { ...dataProps, step: dataProps.min < 0.1 ? dataProps.min : "0.1" },
            // }}
            // onKeyDown={(e) => {
            //   if (inputType === "number") {
            //     const dataValid = Number(e.target.value);
            //     if (Number(targetItem?.min) > dataValid) {
            //       setFieldValue(
            //         `${targetValueMapping}[${String(subindex)}].${String(
            //           name
            //         )}`,
            //         ""
            //       );
            //     } else {
            //       return;
            //     }
            //   }
            // }}
            // onKeyUp={(e) => {
            //   if (inputType === "number") {
            //     const dataValid = Number(e.target.value);
            //     if (Number(targetItem?.min) > dataValid) {
            //       setFieldValue(
            //         `${targetValueMapping}[${String(subindex)}].${String(
            //           name
            //         )}`,
            //         ""
            //       );
            //     } else {
            //       return;
            //     }
            //   }
            // }}
            onBlur={(e) => {
              handleBlur(e);
              inputType === "number"
                ? onValueChangeLengthValues({
                    value: e.target.value,
                    itemvalue: targetItem,
                    setFieldValue,
                    fieldName
                  })
                : onValueChangeLengthValuesText(
                    e,
                    targetItem,
                    `${targetValueMapping}[${String(subindex)}].${String(
                      name
                    )}`,
                    setFieldValue
                  );
            }}
            onChange={handleChange}
          />
        }
        <small style={{ marginTop: -7, display: "block", opacity: 0.75 }}>
          {inputType === "number"
            ? getNumberHint(targetItem)
            : getTextHint(targetItem)}
        </small>
      </div>
      {unitBox && unitName ? <div className="unit-box">{unitName}</div> : null}
    </div>
  );
};

export const PropertyInputFormFields = ({
  type,
  item,
  index,
  arrayName,
  valueKey,
  translateKey,
  translationsMap,
  targetItemMapping,
  targetValueMapping,
  targetUnitMapping,
  name,
  values,
  unitBox,
  inputType,
  valuesLoading,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  setErrors,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { defaultLanguage, fieldSelectedLanguages } = useSelector(
    (x) => x.commonData
  );
  const translations = useSelector((x) => x.translations);
  const [targetItemMap, setTargetItemMap] = useState(null);
  const [targetValueMap, setTargetValueMap] = useState(null);
  const [targetTargetUnitMap, setTargetUnitMap] = useState(null);
  const refs = useRef(true);

  useEffect(() => {
    const firstRender = refs.current;
    if (firstRender && defaultLanguage) {
      refs.current = false;
      setTargetItemMap(get(item, String(targetItemMapping)));
      setTargetValueMap(get(values, String(targetValueMapping)));
      setTargetUnitMap(get(item, String(targetUnitMapping)));
    }
  }, [
    defaultLanguage,
    item,
    targetItemMapping,
    values,
    targetValueMapping,
    targetUnitMapping,
  ]);

  const getUnitName = (subindex) => {
    return targetTargetUnitMap?.find(
      (val) => val.translateId === targetValueMap?.[subindex]?.translateId
    )?.unitName;
  };

  useEffect(() => {
    if (
      !fieldSelectedLanguages.forms ||
      fieldSelectedLanguages.forms?.length === 0
    ) {
      let selectedLanguages = localStorage.getItem("fieldSelectedLanguages");
      selectedLanguages = selectedLanguages
        ? JSON.parse(selectedLanguages)
        : [];
      dispatch(
        setFieldSelectedLanguage({
          translations,
          field: "forms",
          selectedLanguages:
            selectedLanguages?.length > 0
              ? selectedLanguages
              : [defaultLanguage?.translate_id],
        })
      );
    }
  }, [
    fieldSelectedLanguages?.forms,
    defaultLanguage?.translate_id,
    dispatch,
    translations,
  ]);

  let baseArray = item?.[translationsMap];
  if (type === "componentSet") {
    baseArray = get(item, targetItemMapping);
  }

  return (
    <FieldArray
      name={arrayName}
      render={() => (
        <>
          {baseArray?.length > 0 && !valuesLoading && (
            <>
              <div className="row row-sm w-100">
                {baseArray?.map((value, subindex) => (
                  <>
                    {fieldSelectedLanguages?.forms?.includes(
                      value.translateId
                    ) && (
                      <FieldItemBox
                        key={value.translateId}
                        index={index}
                        valueKey={valueKey}
                        translateKey={translateKey}
                        targetValueMapping={targetValueMapping}
                        name={name}
                        values={values}
                        unitBox={unitBox}
                        inputType={inputType}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setErrors={setErrors}
                        targetItem={targetItemMap?.find(
                          (val) =>
                            val.translateId ===
                            targetValueMap?.[subindex]?.translateId
                        )}
                        subindex={subindex}
                        unitName={getUnitName(subindex)}
                        value={value}
                        disabled={disabled}
                      />
                    )}
                  </>
                ))}
              </div>
            </>
          )}
        </>
      )}
    />
  );
};
