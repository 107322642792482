import {
  Popover,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  useParams,
} from "react-router-dom";
import { changeState } from "_store/common.slice";
import { createAlert } from "_store/alert.slice";

export const StateFormPopover = ({
  setStatesPopover,
  statesPopover,
  stateFormOpen,
  changeStateUrl
}) => {
  const dispatch = useDispatch();
  const { stateOptions, stateData } = useSelector((state) => state.commonData);
  let { id } = useParams();
  const handleColumnOptionSelect = (data) =>{
    dispatch(changeState({
      updateUrl: changeStateUrl,
      id,
      data: {[data.key]: data.value}
    })).then(()=> dispatch(createAlert({alertType: "success",msg: "State changed successfully",timeout: 5000 })));
  }


  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={"statePopover"}
        open={stateFormOpen}
        anchorEl={statesPopover}
        onClose={() => setStatesPopover(null)}
        style={{
          left: -100,
        }}
      >
        {/* render all the translations possible */}
        <Box pr={2} p={2} >
          <div
            className="d-flex justify-content-start align-items-center"
            style={{ width: 330, flexDirection: 'column'}}
          >
            <Typography variant="h6" sx={{ mr: 30 }}>State</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            {stateOptions?.map((state) => (
            <FormControl key={state?.key} sx={{ width: 'calc(50% - 10px)', mt: 3 ,mr:1 }}>
              <InputLabel id={`demo-simple-select-label-${state?.key}`}>
                {state?.headerLabel}
              </InputLabel>
              <Select
                labelId={`demo-simple-select-label-${state?.key}`}
                id={`demo-simple-select-${state?.key}`}
                label={state?.headerLabel}
                defaultValue={stateData[state?.key] ?? " "}
                onChange={(e) => handleColumnOptionSelect({ value: e.target.value, key: state?.key })}
                sx={{ mt: 1 }}
              >
              <MenuItem value={" "}>Select</MenuItem>
              {state.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          ))}
          </div>
          </div>
        </Box>
      </Popover>
    </>
  );
};