

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CheckItem from "./CheckItem";
import { getFlattenData, toggleCheck } from "./upstream.util";

const StreamCheckList = ({
    checkListData = [],
    onSetFlattenUpStream
}) => {

    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        setDataList(JSON.parse(JSON.stringify(checkListData)));
    }, [checkListData]);

    const handleToggleCheck = (parentIndex, isChecked) => {
        const list = toggleCheck(dataList, parentIndex, isChecked);
        setDataList(list);

        // Create flatten structure for API payload
        const flattenData = getFlattenData(list);
        onSetFlattenUpStream(flattenData);
    }


    return (
        <Box>
            {dataList.map((item, index) => (
                <CheckItem
                    key={item.id}
                    idx={index}
                    data={item}
                    onToggleCheck={handleToggleCheck}
                />
            ))}
        </Box>
    )
}

export default StreamCheckList;
