/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

//COmponents
import {routes} from "../routes/ProtectedRoutes"
import AppMenuItemComponent from "./AppMenuItem";

// Store
import { updateSidebarBarPinStatus } from "../_store/appconfig.slice";

const AppMenuItem = (props) => {
    const { breadcrumb, link, currentPath, subRoutes = [], secondLevel = false, parentLink=null, toggleSidebarEvent, toggleActiveEvent, currentActive } = props
    const isExpandable = subRoutes && subRoutes.length > 0 && subRoutes.filter((item, subindex)=> item.showInSidebar)?.length > 0 ;
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        if(currentActive===link){
            setOpen(true)}
        else{
            setOpen(false)
        }
    },[currentActive])

    const handleClick = (event) => {
        const linkDisabled = !secondLevel
        if(linkDisabled && isExpandable){
            event.preventDefault()
        }
        else{
            toggleSidebarEvent()
        }
        if(!open && !secondLevel){toggleActiveEvent(link)}
        setOpen(!open)
    }
    const MenuItemRoot = (
      <AppMenuItemComponent linkLevel={secondLevel} link={(secondLevel ? `/${currentPath}/${parentLink}/${link}` : `/${currentPath}/${link}`)} onClick={(e)=>handleClick(e)}>
        { secondLevel && <>- &nbsp;</> }
        <ListItemText primary={breadcrumb}/>
        {/* Display the expand menu if the item has children */}
        {isExpandable && !open && <span className="material-icons">keyboard_arrow_down</span>}
        {isExpandable && open && <span className="material-icons">keyboard_arrow_up</span>}
      </AppMenuItemComponent>
    )
  
    const MenuItemChildren = isExpandable ? (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subRoutes?.map((item, index) => (
            item?.showInSidebar && <AppMenuItem {...item} currentPath={currentPath} parentLink={link} toggleSidebarEvent={toggleSidebarEvent} secondLevel={true} key={`appMenuItem-${Number(index)}`} />
          ))}
        </List>
      </Collapse>
    ) : null
  
    return (
      <>
        {MenuItemRoot}
        {MenuItemChildren}
      </>
    )
  }

const SideBar = ({ pageLocation }) => {
    const dispatch = useDispatch();
    const [sidebarExpand, setSidebarExpand] = useState(false);
    const [sidebarExist, setSidebarExist] = useState(false);
    const { sideBarPinned } = useSelector((x) => x.appconfig);
    const [ sidebarPinnedStatus, setSidebarPinned] = useState(false);
    const [navLinks, setNavLinks] = useState(null);
    const [currentPath, setCurrentPath] = useState(null);
    const [currentActiveNav, setCurrentActiveNav] = useState(null);
    const [navTreeData, setNavTreeData] = React.useState(null)
    const path = pageLocation;

    useEffect(() => {
        getCurretNav();
        if(sidebarPinnedStatus){
            setSidebarExpand(sidebarPinnedStatus)
        }
    },[path])

    useEffect(() => {
        if(sidebarPinnedStatus){
            setSidebarExpand(true)
        }
    },[sidebarPinnedStatus])

    useEffect(() => {
        if(sidebarPinnedStatus !== sideBarPinned){
            setSidebarPinned(sideBarPinned)  
        }
    },[sideBarPinned])
    
    useEffect(() => {
        if(sidebarPinnedStatus !== sideBarPinned ){
            setSidebarExpand(sidebarPinnedStatus)
        }
    },[sidebarPinnedStatus])

    useEffect(() => {
        if(navLinks && navLinks?.subRoutes){
            setSidebarExist(true)
            setNavTreeData(navLinks?.subRoutes)
        }
        else{
            setSidebarExist(false)
        }
    },[navLinks])
    
    const getCurretNav =() => {
        let routesData = routes;
        let currentMainNav = routesData.find(item => item.link.indexOf(path.split('/')[1]) !== -1);
        setCurrentPath(path.split('/')[1]);
        setCurrentActiveNav(path.split('/')[2]);
        setNavLinks(currentMainNav)
    }

    const toggleSidebar = () => {
        if(!sidebarPinnedStatus){
            return setSidebarExpand(!sidebarExpand)
        }
        else{
            setSidebarExpand(true)
        }
    }

    const toggleActive = (data) => {
        setCurrentActiveNav(data)
    }

    const togglePinned = () => {
        dispatch(updateSidebarBarPinStatus(!sidebarPinnedStatus))
        return setSidebarPinned(!sidebarPinnedStatus)
    }

    return (
        <>
        { sidebarExist && navTreeData?.filter(item=> item?.showInSidebar !== false).length > 0 && 
            <div className={`sidebar ${ sidebarExpand ? "expanded" : ""} ${ sidebarPinnedStatus ? "sticky" : ""}`} id="sidenav">
                <div className="toggle-btns">
                    <button className={`sidebar-toggle ${ sidebarExpand ? "toggled" : ""}`} disabled={sidebarPinnedStatus} onClick={()=>toggleSidebar()}>
                        <span className="material-icons">{ sidebarExpand? 'arrow_back_ios':'arrow_forward_ios'}</span>
                    </button>
                    <button className={`pin-toggle ${ sidebarPinnedStatus ? "pinned" : ""}`}  onClick={()=>togglePinned()}><span className="material-icons">push_pin</span></button>
                </div>
                    
                <div className="w-100">
                    <div className="sidebar-container">
                        <ul className="sidebar-nav nav flex-column">
                            {navTreeData?.filter(item=> item?.showInSidebar !== false)?.map((item, index) => (
                                <li key={`nav-${Number(index)}`}><AppMenuItem {...item} currentPath={currentPath} currentActive={currentActiveNav} toggleActiveEvent={(data)=>toggleActive(data)} toggleSidebarEvent={()=>toggleSidebar()}/></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        }</>
    );
};

export default SideBar;