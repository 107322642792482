import { useEffect, useRef } from "react";
import { FieldArray } from "formik";
import { TextField } from "@mui/material";
import { getTranslationColumnGrid, getTranslationLabel } from "_utils/commonFunctions";
import ErrorMessage from "_components/ErrorMessage";
import { useSelector, useDispatch } from "react-redux";
import { setFieldSelectedLanguage } from "_store/common.slice";


export const InputFormFields = ({
  values,
  valuesLoading,
  errors,
  touched,
  handleChange,
  handleBlur,
  name,
  valueKey,
  label,
  labelType,
  disabled = false,
  namePrefix,
  additionalKey
}) => {
  const dispatch = useDispatch();
  const { defaultLanguage, fieldSelectedLanguages } = useSelector((x) => x.commonData);
  const translations = useSelector((x) => x.translations);
  const refs = useRef(true);
  const CustomTag = `${labelType}`;

  useEffect(() => {
    const firstRender = refs.current;
    if (firstRender && defaultLanguage) {
      refs.current = false;
    }
  });

  useEffect(() => {
    if ((!fieldSelectedLanguages.forms || fieldSelectedLanguages.forms?.length === 0) && defaultLanguage?.translate_id) {
      let selectedLanguages = localStorage.getItem('fieldSelectedLanguages');
      selectedLanguages = selectedLanguages ? JSON.parse(selectedLanguages) : [];
      dispatch(setFieldSelectedLanguage({ translations, field: 'forms', selectedLanguages: selectedLanguages?.length > 0 ? selectedLanguages : [defaultLanguage?.translate_id] }));
    }
  }, [fieldSelectedLanguages?.forms, defaultLanguage?.translate_id, dispatch, translations])


  return (
    <div className="form-group col-md-12">
      <FieldArray
        name={name}
        render={(arrayRootHelpers) => (
          <>
            {values?.[name]?.length > 0 && !valuesLoading && (
              <>
                <CustomTag>{label}</CustomTag>
                <div className="row row-sm">
                  {values?.[name]?.map((value, index) => {
                    return (
                      <>
                        {fieldSelectedLanguages?.forms?.includes(value.translateId) &&
                          <>
                            <div key={value.translateId} className={`${getTranslationColumnGrid()}`}>
                              <TextField
                                id={`[${name}][${index}]`}
                                label={getTranslationLabel(value.translateId)}
                                name={namePrefix ?
                                  (additionalKey ? `${namePrefix}.[${name}][${index}].[${valueKey}].[${additionalKey}]` : `${namePrefix}.[${name}][${index}].[${valueKey}]`) :
                                  (additionalKey ? `[${name}][${index}].[${valueKey}].[${additionalKey}]` : `[${name}][${index}].[${valueKey}]`)}
                                value={additionalKey ? value?.[valueKey]?.[additionalKey] : (value?.[valueKey] || '')}
                                margin="normal"
                                fullWidth
                                error={Boolean(
                                  errors?.[name] && touched?.[name]?.length > 0
                                )}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={disabled}
                              />
                            </div>
                          </>
                        }
                      </>
                    )
                  })}
                </div>
              </>
            )}
          </>
        )}
      />
      {errors?.[name] && touched?.[name]?.length > 0 ? (
        <ErrorMessage error={errors?.[name]} />
      ) : null}
    </div>
  );
};
