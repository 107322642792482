/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import InnerLoader from "_components/InnerLoader";
import { getBlockListById } from "_store/merchandising.slice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ShowLinkedPages = ({
  optionsData,
  expanded,
  setExpanded,
  heading,
  page
}) => {
  const [treeList, setTreeList] = useState();
  const dispatch = useDispatch();
  const fetchBlockList = async () => {
    const blockIds = {
      ids: optionsData?.templateBlockMaps.map(item => item?.blockId)
    };
    if (blockIds) {
      return dispatch(getBlockListById(blockIds)).then((res) => {
        if (res?.payload?.length > 0) {
          const result = {
            pageUrl: `../../cms/templates/${optionsData?.id}/view?typeName=${optionsData?.name}&listPage=view`,
            pageName: "Page",
            id: optionsData?.id,
            name: optionsData?.name,
            childData: optionsData.templateBlockMaps.map(item => {
              const block = res?.payload.find(block => block.id === item?.blockId);
              return {
                ...item,
                pageUrl: `../../cms/blocks/${block?.id}/view?typeName=${block?.name}&listPage=view`,
                pageName: "Block",
                ...(block ? {
                  name: block.name, childData: block.sections?.map(section => {
                    return {
                      id: section?.id,
                      name: section?.name,
                      pageName: "Section",
                      pageUrl: `../../cms/sections/${section?.id}/view?type=${section?.type}&listPage=view`,
                    }
                  })
                } : {})
              };
            })
          }
          setTreeList(result)
        }
        else {
          setTreeList(optionsData)
        }
      });
    }
  }
  const fetchSectionList = () => {
    const sectionData = {
      pageUrl: `../../blocks/${optionsData?.id}/view?typeName=${optionsData?.name}&listPage=view`,
      pageName: "Block",
      id: optionsData?.id,
      name: optionsData?.name, 
      childData: optionsData?.sections.map(item => {
        return{
        id: item?.id,
        pageName: "Section",
        pageUrl: `../../sections/${item?.id}/view?type=${item?.type}&listPage=view`,
        name: item?.name,
        type: item?.type,
      }})
    };
    setTreeList(sectionData)
  }

  useEffect(() => {
    if (page === 'pages') {
      fetchSectionList();
    } else {
    fetchBlockList();
    }
  }, [optionsData]);

  const renderTree = (nodes) => (
    <div className="multi-level-child">
     <span className="heading">{nodes?.pageName}:</span>  <TreeView
        key={nodes?.id}
        defaultCollapseIcon={
          nodes?.childData?.length > 0 ? (
            <div className="material-icons">expand_more</div>
          ) : null
        }
        defaultExpandIcon={
          nodes?.childData?.length > 0 ? (
            <div className="material-icons">chevron_right</div>
          ) : null
        }
        onNodeToggle={handleToggle}
        expanded={nodes?.childData?.length > 0 ? expanded : []}
      >
        <TreeItem
          expandIcon={nodes?.childData?.length > 0 ? <div className="material-icons">chevron_right</div> : null}
          onClick={() => handleToggle(nodes?.id)}
          key={nodes?.id}
          nodeId={String(nodes?.id)}
          label={
            <div>
              {nodes?.name}
            </div>
          }
          className="multi-level-drop-box"
        >
          <div className="tree-item-box">
            {Array.isArray(nodes?.childData)
              ? nodes.childData.map((nd) =>
                renderTree(nd)
              )
              : null}
          </div>
        </TreeItem>
        <a href={nodes?.pageUrl} target="_blank" className="drop-link" rel="noreferrer"> View {nodes?.pageName}</a>
      </TreeView >
    </div>
  );

  const handleToggle = (nodeIds) => {
    setExpanded((exp) =>
      exp.includes(nodeIds)
        ? exp.filter((item) => item !== nodeIds)
        : [...exp, nodeIds]
    );
  };

  return (
    <>
      {heading && (
        <Box
          sx={{
            marginY: "0.5rem",
            fontWeight: "400",
            fontSize: "18px",
            marginTop: "20px",
          }}
        >
          {heading}
        </Box>
      )}
      {!treeList && <InnerLoader isBlockLoader={true}  />}
      <div className="tree-item-box multi-level-drop-wrap" >{renderTree(treeList)}</div>
    </>
  );
};
export default ShowLinkedPages;
