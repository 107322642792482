import { FormControlLabel, Switch } from "@mui/material";
import ErrorMessage from "_components/ErrorMessage";

export default function IsSolrSearchable({
  values,
  errors,
  touched,
  setFieldValue,
  disabled
}) {
  return (
    <div className={`col-xl-3 col-md-4`}>
      <h4>&nbsp;</h4>
      <FormControlLabel
        name="isSolrSearchable"
        disabled={disabled}
        control={
          <Switch
            name="isSolrSearchable"
            checked={values?.isSolrSearchable}
            onChange={(e) => {
              setFieldValue(
                `isSolrSearchable`,
                e.target.checked ? true : false
              );
            }}
          />
        }
        label="Is Solr Searchable"
      />
      {errors?.isSolrSearchable && touched?.isSolrSearchable ? (
        <ErrorMessage error={errors?.isSolrSearchable} />
      ) : null}
    </div>
  );
}
