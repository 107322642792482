import React, { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import ListItemButton from '@mui/material/ListItemButton';

const AppMenuItemComponent = (props) => {
  const { className, onClick, link, children, linkLevel} = props
  // If link is not set return the orinary ListItem
//   if (linkLevel && (!link || typeof link !== 'string')) {
  if (!linkLevel) {
    return (
        <ListItemButton
            className={className}
            children={children}
            component={forwardRef((props, ref) => <NavLink {...props} ref={ref} activeclassname="active"/>)}
            onClick={onClick}
            to={link}
        />
    )
  }

  // Return a LitItem with a link component
  return (
    <ListItemButton
      className={className}
      children={children}
      component={forwardRef((props, ref) => <NavLink {...props} ref={ref} activeclassname="active"/>)}
      to={link}
      onClick={onClick}
    />
  )
}

export default AppMenuItemComponent
