import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_utils';
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "upStream";
const initialState = {
  isOpen: false,
  id: "",
  title: "",
  formData: {},
  getUpstreamUrl: "",
  submitUrl: "",
  successRedirectUrl: "",
  state: {},
  submitCallback: null,
  isLoadingFetch: false,
  upStreamsList: []
};


/**
 * Get up stream list
 * @param {object} {url,data}
 */
export const getUpStreamsList = createAsyncThunk(
  `${sliceName}/getUpStreamsList`,
  async ({ url, data }) => await fetchWrapper.post(`${baseUrl}${url}`, data)
);

/**
 * Post up stream detail
 * @param {object} {url,data}
 */
export const postUpStreams = createAsyncThunk(
  `${sliceName}/postUpStreams`,
  async ({ url, data }) => await fetchWrapper.post(`${baseUrl}${url}`, data)
);

export const  editMetalWrapper = createAsyncThunk(
  `${sliceName}/postUpStreams`,
  async ({ url, data }) => await fetchWrapper.post(`${baseUrl}${url}`, data)
);

export const editMWrapper = createAsyncThunk(
  `${sliceName}/editWrapper`,
  async ({ url, data, id }) => await fetchWrapper.post(`${baseUrl}${url}${id}`, data)
);

export const upStreamSlice = createSlice({
  name: "upStream",
  initialState,
  reducers: {
    setUpStreamModal: (state, action) => {
      return { ...state, ...action.payload, isOpen: true };
    },
    setUpStreamList: (state, action) => {
      state.upStreamsList = action.payload
    },
    resetUpStreamModal: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    
    // Fetch up streams list
    builder.addCase(getUpStreamsList.pending, (state) => {
      state.upStreamsList = [];
      state.isLoadingFetch = true;
    })
    .addCase(getUpStreamsList.rejected, (state) => {
      state.isLoadingFetch = false;
      state.upStreamsList = [];
    })
    .addCase(getUpStreamsList.fulfilled, (state, action) => {
      const id = action.meta.arg.data.idList[0];
      if(action.payload && Array.isArray(action.payload[id])) {
        state.upStreamsList = action.payload[id];
      } else {
        state.upStreamsList = [];
      }
      
      state.isLoadingFetch = false;
    });

  }
});

export const upStreamActions = upStreamSlice.actions;

export default upStreamSlice.reducer;