import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "placeholderData";

// APIs
/*Postion Units */
/* table Meta Data */

// StoneTeam
export const getPlaceholderMetaData = createAsyncThunk(
  `${sliceName}/getPlaceholderMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/placeholder/getMetadata`)
);

export const getPlaceholderRowData = createAsyncThunk(
  `${sliceName}/getPlaceholderRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/placeholder/getAll?${params || ""}`)
);

export const getPlaceholderList = createAsyncThunk(
  `${sliceName}/getPlaceholderList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/placeholder/dropdown/getAll?${params || ""}`)
);


export const deletePlaceholder = createAsyncThunk(
  `${sliceName}/deletePlaceholder`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/placeholder/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const getPlaceholderById = createAsyncThunk(
  `${sliceName}/getPlaceholderById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/placeholder/get/${id}?${params ? `${params || ""}` : ''}`
    )
  }
);

export const addPlaceholder = createAsyncThunk(
  `${sliceName}/addPlaceholder`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/placeholder/add`, values);
    return res || res === "" ? res : null;
  }
);

export const uniquePlaceholderList = createAsyncThunk(
  `${sliceName}/uniquePlaceholderList`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/component/unique-placeholder/list`, values);
    return res || res === "" ? res : null;
  }
);

export const getPlaceholderRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPlaceholderRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/placeholder/version-history/get/${id}?${params || ""}`)
);

export const duplicatePlaceholder = createAsyncThunk(
  `${sliceName}/duplicatePlaceholder`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/placeholder/duplicate/${id}`)
);

export const getPlaceholderMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPlaceholderMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/placeholder/getMetadata?versioning=true&${params || ""}`)
);

export const getPlaceholderData = createAsyncThunk(
  `${sliceName}/getPlaceholderSlice`,
  async () => await fetchWrapper.get(`${baseUrl}/placeholder?filter=`)
);
