/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem } from "@mui/material";
import AutocompleteForFormikMulti from "_components/AutocompleteForFormikMulti";
import CustomizedSelectForFormik from "_components/CustomizedSelectForFormik";
import { getTranslationColumnGrid } from "_utils/commonFunctions";
import { Field } from "formik";
import { get } from "lodash";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFieldSelectedLanguage } from "_store/common.slice";

export const PropertySelectFormFieldsWithOutTranslate = ({
  item,
  translateKey,
  targetUnitMapping,
  name,
  value,
  inputType,
  unitBox,
  optionsMapping,
  handleChange,
  handleBlur,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const { defaultLanguage, fieldSelectedLanguages } = useSelector(
    (x) => x.commonData
  );
  const translations = useSelector((x) => x.translations);
  const [optionsMap, setOptionsMap] = useState(null);
  const [unitName, setUnitName] = useState(null);
  const refs = useRef(true);

  useEffect(() => {
    const firstRender = refs.current;
    if (firstRender && defaultLanguage) {
      refs.current = false;
      const optionArray = get(item, String(optionsMapping))?.sort((a,b) =>  a.sequence - b.sequence).map(
        (arrayItem) => ({
          label:
            arrayItem[translateKey]?.find(
              (val) => val.translateId === defaultLanguage?.translate_id
            )?.value || arrayItem.id,
          value: arrayItem.id,
        })
      );
      const unitNameValue = get(item, String(targetUnitMapping))?.find(
        (val) => val.translateId === defaultLanguage?.translate_id
      )?.unitName;
      setOptionsMap(optionArray);
      setUnitName(unitNameValue);
    }
  }, [defaultLanguage, item, optionsMapping]);

  useEffect(() => {
    if (
      !fieldSelectedLanguages.forms ||
      fieldSelectedLanguages.forms?.length === 0
    ) {
      let selectedLanguages = localStorage.getItem("fieldSelectedLanguages");
      selectedLanguages = selectedLanguages
        ? JSON.parse(selectedLanguages)
        : [];
      dispatch(
        setFieldSelectedLanguage({
          translations,
          field: "forms",
          selectedLanguages:
            selectedLanguages?.length > 0
              ? selectedLanguages
              : [defaultLanguage?.translate_id],
        })
      );
    }
  }, [
    fieldSelectedLanguages?.forms,
    defaultLanguage?.translate_id,
    dispatch,
    translations,
  ]);

  return (
    <div className="row row-sm w-100">
      <div
        className={`${
          inputType === "multiselect" ? "col-12" : getTranslationColumnGrid()
        } ${unitBox ? "value-box" : ""}`}
        style={{ marginBottom: 8 }}
      >
        <Field
          id={name}
          label="select"
          name={name}
          value={value}
          margin="normal"
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Value"
          component={
            inputType === "list"
              ? CustomizedSelectForFormik
              : AutocompleteForFormikMulti
          }
          options={inputType !== "list" && (optionsMap || [])}
          disabled={readOnly}
          readOnly={readOnly}
        >
          {inputType === "list" &&
            optionsMap?.map((arrayItem) => (
              <MenuItem key={arrayItem.value} value={arrayItem.value}>
                {arrayItem.label}
              </MenuItem>
            ))}
        </Field>
        {unitBox && unitName ? (
          <div className="unit-box">{unitName}</div>
        ) : null}
      </div>
    </div>
  );
};
