import * as React from 'react';
import {
  Typography,
} from "@mui/material";

const ErrorMessage = ({error}) => {
  return (
    <Typography variant="span" component="span" color="#d32f2f">
     {typeof error === 'object' ? "Please enter valid data." : error}
  </Typography>
  );
}

export default ErrorMessage;
