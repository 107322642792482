import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "priceCalculator";

export const getAllVendors = createAsyncThunk(
  `${sliceName}/getAllVendors`,
  async (params) => await fetchWrapper.get(`${baseUrl}/vendor/dropdown/getAll?${params || ""}`)
);

export const calculateQuotation = createAsyncThunk(
  `${sliceName}/calculateQuotation`,
  async (body) =>
    await fetchWrapper.post(`${baseUrl}/product/quotation`, body)
);

export const calculateSalesQuotation = createAsyncThunk(
  `${sliceName}/calculateSalesQuotation`,
  async (body) =>
    await fetchWrapper.post(
      `${baseUrl}/product/quotation`,
      body
    )
);


// --------------------- Price Calculator Start Here ---------------------

export const addPriceCalculator = createAsyncThunk(
  `${sliceName}/addPriceCalculator`,
  async (body) => await fetchWrapper.post(`${baseUrl}/product/quotation/add`, body)
);

export const updatePriceCalculator = createAsyncThunk(
  `${sliceName}/updatePriceCalculator`,
  async (body) => await fetchWrapper.post(`${baseUrl}/product/quotation/update/${body.id}`, body)
);


export const getPriceCalculatorById = createAsyncThunk(
  `${sliceName}/getPriceCalculatorById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/product/quotation/get/${id}`)
);

export const getPriceCalculatorMetaData = createAsyncThunk(
  `${sliceName}/getPriceCalculatorMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/product/quotation/getMetadata?${params}`)
);

export const getPriceCalculatorRowData = createAsyncThunk(
  `${sliceName}/getPriceCalculatorRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/product/quotation/getAll?${params}`)
);

export const deletePriceCalculator = createAsyncThunk(
  `${sliceName}/deletePriceCalculatorRowData`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/product/quotation/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const duplicatePriceCalculator = createAsyncThunk(
  `${sliceName}/duplicatePriceCalculator`,
  async ({ id }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/product/quotation/duplicate/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getPriceCalculatorMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPriceCalculatorMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/product/quotation/getMetadata?versioning=true&${params}`)
);

export const getPriceCalculatorRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPriceCalculatorRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/product/quotation/version-history/get/${id}?${params}`)
);

// --------------------- Price Calculator Ends Here ---------------------

// slice
export const priceCalculatorSlice = createSlice({
  name: sliceName,
  initialState: {
    calculatorResult: null,
    calculatorSimpleResulte: null,
    calculatorFormData: null,
  },
  reducers: {
    setProductPriceCalculateResult: (state, action) => {
      return { ...state, calculatorResult: action.payload };
    },
    setProductPriceCalculateSimpleResult: (state, action) => {
      return { ...state, calculatorSimpleResulte: action.payload };
    },
    setPriceCalculatorFormData: (state, action) => {
      return { ...state, calculatorFormData: action.payload };
    },
  },
});

export const {
  setProductPriceCalculateResult,
  setPriceCalculatorFormData,
  setProductPriceCalculateSimpleResult,
} = priceCalculatorSlice.actions;

export default priceCalculatorSlice.reducer;
