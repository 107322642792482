import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";

const CustomizedSelectForFormik = ({ children, form, field, label, disabled, onChange, readOnly = false }) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  return (
    <FormControl fullWidth className="selectField">
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        name={name}
        disabled={disabled}
        labelId={`${name}-label`}
        id={`${name}-label`}
        value={value}
        label={label}
        readOnly={readOnly}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          if (onChange) onChange(e);
        }}
      >
        <MenuItem key="a0" value="">--Select--</MenuItem>
        {children}
      </Select>
    </FormControl>
  );
};

export default CustomizedSelectForFormik;
