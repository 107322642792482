/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState} from "react";
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routes } from "routes/ProtectedRoutes";

// Lodash
import { find } from "lodash"

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();
  const [ filteredMappings, setRouteMapping] = useState(false);

  useEffect(() => {
    if (routes){
      let ad = []
      routes.forEach(function(item, index){
        let routePath = { path:`/${item.link}`, title: item.breadcrumb};
        ad.push(routePath)
          if(item['subRoutes']) {
              item['subRoutes'].forEach(function(subItem){
                const subLink =  { path: routePath.path+`/${subItem.link}`, title: subItem.breadcrumb};
                ad.push(subLink)
              })
          }
      });
      setRouteMapping(ad)
    };
  }, [routes]);

  return (
    <div className="breadcrumbs">
      <ul>
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
            return(
              match.pathname !== "/" && index < 3 && find(filteredMappings, el => el?.path === match.pathname)?.title && 
              <li className="nav-item" key={match.pathname}>
                <NavLink to={match.pathname}>
                  { find(filteredMappings, el => el?.path === match.pathname)?.title}
                </NavLink>
              </li>
            )
          }
        )}
      </ul>
    </div>
  );
}

export default Breadcrumbs;
