import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "tagData";

// APIs

export const getTagsData = createAsyncThunk(
  `${sliceName}/getTagsData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/tag/get`)
);

export const getTagsDataById = createAsyncThunk(
  `${sliceName}/getTagsDataById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/tag/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getTagsRowData = createAsyncThunk(
  `${sliceName}/getTagRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/tag/getAll?${params || ""}`)
);

export const getTagsMetaData = createAsyncThunk(
  `${sliceName}/getTagsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/tag/getMetadata`)
);

export const deleteTag = createAsyncThunk(
  `${sliceName}/deleteTag`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/tag/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const duplicateTag = createAsyncThunk(
  `${sliceName}/duplicateTag`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/tag/duplicate/${id}`)
);

export const getTagById = createAsyncThunk(
  `${sliceName}/getTagById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/tag/get/${id}`)
);

export const addTag = createAsyncThunk(
  `${sliceName}/addTag`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/tag/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editTag = createAsyncThunk(
  `${sliceName}/editTag`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/tag/update/${values.id}`, values.formData);
    return res || res === "" ? res : null;
  }
);

export const getTagMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getTagMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/tag/getMetadata?versioning=true&${params || ""}`)
);

export const getTagRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getTagRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/tag/version-history/get/${id}?${params || ""}`)
);

// slice
export const tagSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default tagSlice.reducer;
