import * as React from "react";

const CardBody = ({ children, HeaderTitle, customClass ='' }) => {
  return (
    <div style={{ margin: "0 auto" }}>
     
      <div className={`card ${customClass}`}>
      {
        HeaderTitle && <div className="card-header">
          <h5>{HeaderTitle}</h5>
          </div>
      }
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default CardBody;
