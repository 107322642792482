import React from "react"

const ErrorIcon = () => (
  <svg
    height="32px" version="1.1" viewBox="0 0 32 32" width="32px" xmlns="http://www.w3.org/2000/svg" >
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g fill="#000" id="icon-57-sad-face"><path d="M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M19.6215213,10.9670954 L19.0623284,11.796133 L22.3784787,14.0329046 L22.9376716,13.203867 L19.6215213,10.9670954 L19.6215213,10.9670954 Z M10.0623284,13.203867 L10.6215213,14.0329046 L13.9376716,11.796133 L13.3784787,10.9670954 L10.0623284,13.203867 L10.0623284,13.203867 Z M16.4813232,21 C13,21 11,23 11,23 L11,22 C11,22 13,20 16.4813232,20 C19.9626465,20 22,22 22,22 L22,23 C22,23 19.9626465,21 16.4813232,21 L16.4813232,21 Z"/></g>
    </g>
  </svg>
)
export default ErrorIcon
