import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import FamilysVersionHistory from "views/pim/family/family/familyVersionHistory";
import { fetchWrapper } from "_utils";


const baseUrl = process.env.REACT_APP_VENDOR_FEED_API;
const name = "vendor";
const sliceName = name;

const initialState={
    loading:false,
    vendors:[],
    vendor:{},
    error:"",
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: null,
}

export const getVendorsMetaData = createAsyncThunk(
    `${sliceName}/getUsersMetaData`,
    async () => await fetchWrapper.get(`${baseUrl}vendor/config/getMetadata`)
  );
  export const deleteVendor = createAsyncThunk(
    `${sliceName}/deleteUsers`,
    async ({ vendorId }) => {
      const res = await fetchWrapper.post(`${baseUrl}vendor/config/delete/${vendorId}`, { vendorId });
      return res || res === "" ? { vendorId } : null;
    }
  );
  export const getVendorsRowData = createAsyncThunk(
    `${sliceName}/getUsersRowData`,
    async (params) => await fetchWrapper.get(`${baseUrl}vendor/config/getAll?${params || ""}`)
  );

export const fetchVendors=createAsyncThunk('vendor/fetchVendors',async ({queryPageIndex, queryPageSize})=>{
   
   const offset = queryPageIndex+1;
    const response = await axios
        .get(`${baseUrl}/vendor/config?page=${offset}&limit=${queryPageSize}`);
    return response.data;

})

export const deleteVendorById=createAsyncThunk('vendor/deleteVendor',async (vendor_id)=>{
    const response = await axios
        .delete(`${baseUrl}/vendor/config/${vendor_id}`);
    return response.data;

})

export const getVendorById=createAsyncThunk('vendor/getVendorById',async (vendor_id)=>{
    const response = await axios
        .get(`${baseUrl}/vendor/config/${vendor_id}`);
    return response.data;

})

export const createVendor=createAsyncThunk('vendor/createVendor',async (data)=>{
    const response = await axios
        .post(`${baseUrl}/vendor/config`,JSON.stringify(data),{ headers: { "Content-Type": "application/json" } });
        
    return response.data;

})

export const updateVendor=createAsyncThunk('vendor/updateVendor',async ({data,vendor_id})=>{
   
    const response = await axios
        .patch(`${baseUrl}/vendor/config/${vendor_id}`,JSON.stringify(data),{ headers: { "Content-Type": "application/json" } });
        
    return response.data;

})



const vendorSlice=createSlice({
    name:"vendor",
    initialState,
    reducers:{
        setPageChanged: (state, action) => {
            return {...state, queryPageIndex: action.payload}
        },
        setPageSizeChanged: (state, action) => {
        return {...state, queryPageSize: action.payload}
        },

        setTotalCountChanged: (state, action) => {
        return {...state, totalCount: action.payload}
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchVendors.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(fetchVendors.fulfilled,(state,action)=>{
            state.loading=false
            state.vendors=action.payload.vendors
            state.totalCount=action.payload.count
            state.error='';
            state.vendor={}
        })
        builder.addCase(fetchVendors.rejected,(state,action)=>{
            state.loading=false
            state.vendors=[]
            state.error=action.error.message;
            state.vendor={}
        })
        builder.addCase(createVendor.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(createVendor.fulfilled,(state,action)=>{
            state.loading=false
            state.vendor=action.payload
            state.error='';
            state.vendors=[]
        })
        builder.addCase(createVendor.rejected,(state,action)=>{
            state.loading=false
            state.vendors=[]
            state.error=action.error.message;
            state.vendor={}
        })
        builder.addCase(updateVendor.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(updateVendor.fulfilled,(state,action)=>{
            state.loading=false
            state.vendor=action.payload
            state.error='';
            // state.vendors=[]
        })
        builder.addCase(updateVendor.rejected,(state,action)=>{
            state.loading=false
            // state.vendors=[]
            state.error=action.error.message;
            // state.vendor={}
        })
        builder.addCase(getVendorById.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(getVendorById.fulfilled,(state,action)=>{
            state.loading=false
            state.vendor=action.payload
            state.error='';
            state.vendors=[]
        })
        builder.addCase(getVendorById.rejected,(state,action)=>{
            state.loading=false
            state.vendors=[]
            state.error=action.error.message;
            state.vendor={}
        })

        builder.addCase(deleteVendorById.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(deleteVendorById.fulfilled,(state,action)=>{
           
            state.loading=false
            state.vendor=action.payload
            state.error='';
            state.vendors=state.vendors.filter(vendor=>vendor.vendor_id!==action.meta.arg)
        })
        builder.addCase(deleteVendorById.rejected,(state,action)=>{
            state.loading=FamilysVersionHistory
            state.error=action.error.message;
            state.vendor={}
        })
    }
})


export default vendorSlice.reducer

export const {setPageChanged,setPageSizeChanged,setTotalCountChanged}= vendorSlice.actions;

