import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "serviceData";

// APIs
/*Service Units */
/* table Meta Data */
export const getServiceTypesMetaData = createAsyncThunk(
  `${sliceName}/getServiceTypesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/service/service-type/getMetadata`)
);

export const getServiceTypesRowData = createAsyncThunk(
  `${sliceName}/getServiceTypesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/service/service-type/getAll?${params || ""}`)
);

export const getServiceTypesList = createAsyncThunk(
  `${sliceName}/getServiceTypesList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/service/service-type/dropdown/getAll?${params || ""}`)
);
/* table Meta Data */

export const getAllServiceUnits = createAsyncThunk(
  `${sliceName}/getAllServiceUnits`,
  async () => await fetchWrapper.get(`${baseUrl}/service/service-unit/getAll`)
);

export const getServiceUnitId = createAsyncThunk(
  `${sliceName}/getServiceUnitById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/service/service-unit/get/${id}${params ? `?${params || ""}` : ''}`)
  }
);

export const addServiceUnit = createAsyncThunk(
  `${sliceName}/addServiceUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/service-unit/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteServiceUnit = createAsyncThunk(
  `${sliceName}/deleteServiceUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/service-unit/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

//ServiceType
/* table Meta Data */
export const getServiceUnitsMetaData = createAsyncThunk(
  `${sliceName}/getServiceUnitsMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/service/service-unit/getMetadata`)
);

export const getServiceUnitsRowData = createAsyncThunk(
  `${sliceName}/getServiceUnitsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/service/service-unit/getAll?${params || ""}`)
);
/* table Meta Data */

export const getServiceTypeById = createAsyncThunk(
  `${sliceName}/getServiceTypeByIdWrapper`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/service/service-type/get/${id}${params ? `?${params || ""}` : ''}`)
  })

export const addServiceType = createAsyncThunk(
  `${sliceName}/addServiceType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/service-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const addServiceTypeWrapper = createAsyncThunk(
  `${sliceName}/addServiceTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/service-type/wrapper-service-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteServiceType = createAsyncThunk(
  `${sliceName}/deleteServiceType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/service-type/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

//Service Property
/* table Meta Data */
export const getServicePropertiesMetaData = createAsyncThunk(
  `${sliceName}/getServicePropertiesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/service/service-property/getMetadata`)
);

export const getServicePropertiesRowData = createAsyncThunk(
  `${sliceName}/getServicePropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/service/service-property/getAll?${params || ""}`
    )
);
/* table Meta Data */

export const getAllServiceProperties = createAsyncThunk(
  `${sliceName}/getAllServiceProperties`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/service/service-property/getAll`)
);

export const getServicePropertyById = createAsyncThunk(
  `${sliceName}/getServicePropertyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await await fetchWrapper.get(
      `${baseUrl}/service/service-property/get/${id}?filter[include][][relation]=servicePropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addServicePropertyWrapper = createAsyncThunk(
  `${sliceName}/addServicePropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/service-property/wrapper-service-property/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteServiceProperty = createAsyncThunk(
  `${sliceName}/deleteServiceProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/service-property/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

// Services
/* table Meta Data */
export const getServicesMetaData = createAsyncThunk(
  `${sliceName}/getServicesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/service/getMetadata`)
);

export const getServicesMetaDataByServiceId = createAsyncThunk(
  `${sliceName}/getServicesMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/service/getMetadata?${params || ""}`)
);

export const getServicesTagByServicesId = createAsyncThunk(
  `${sliceName}/getServicesTagByServicesId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/service/service-type/${id}/all-tags/get`)
);

export const getServicesRowData = createAsyncThunk(
  `${sliceName}/getServicesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/service/getAll?${params || ""}`)
);
export const getServiceById = createAsyncThunk(
  `${sliceName}/getServiceById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/service/get/${id}?filter[include][][relation]=servicePropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);


export const addService = createAsyncThunk(
  `${sliceName}/addService`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/service/add`, values);
    return res || res === "" ? res : null;
  }
);
export const addServiceWrapper = createAsyncThunk(
  `${sliceName}/addServiceWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/wrapper-service/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteService = createAsyncThunk(
  `${sliceName}/deleteService`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/service/delete${deletePermanent ? '-permanent' : ''}/${id}`, {
      id,
    });
    return res;
  }
);

//getServicePropertiesByServiceTypeId
export const getServicePropertiesByServiceTypeId = createAsyncThunk(
  `${sliceName}/getServicePropertiesByServiceTypeId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/service/service-type/get/${id}`)
);

// Duplicate Actions
export const duplicateService = createAsyncThunk(
  `${sliceName}/duplicateService`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/service/duplicate/${id}`)
);

export const duplicateServiceUnit = createAsyncThunk(
  `${sliceName}/duplicateServiceUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/service/service-unit/duplicate/${id}`)
);

export const duplicateServiceType = createAsyncThunk(
  `${sliceName}/duplicateServiceType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/service/service-type/duplicate/${id}`)
);

export const duplicateServiceProperty = createAsyncThunk(
  `${sliceName}/duplicateServiceProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/service/service-property/duplicate/${id}`)
);


export const getServicesMetaDataByServiceIdForVersion = createAsyncThunk(
  `${sliceName}/getServicesMetaDataForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/service/getMetadata?versioning=true&${params || ""}`)
);

export const getServiceTypesMetaDataByServiceIdForVersion = createAsyncThunk(
  `${sliceName}/getServiceTypesMetaDataByServiceIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/service/service-type/getMetadata?versioning=true&${params || ""}`)
);

export const getServicePropertiesMetaDataByServiceIdForVersion = createAsyncThunk(
  `${sliceName}/getServicePropertiesMetaDataByServiceIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/service/service-property/getMetadata?versioning=true&${params || ""}`)
);

export const getServiceUnitsMetaDataByServiceIdForVersion = createAsyncThunk(
  `${sliceName}/getServiceUnitsMetaDataByServiceIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/service/service-unit/getMetadata?versioning=true&${params || ""}`)
);

export const getServicesRowDataByServiceId = createAsyncThunk(
  `${sliceName}/getServicesRowDataByServiceId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/service/version-history/get/${id}?${params || ""}`)
);

export const getServiceTypesRowDataByServiceId = createAsyncThunk(
  `${sliceName}/getServiceTypesRowDataByServiceId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/service/service-type/version-history/get/${id}?${params || ""}`)
);

export const getServicePropertiesRowDataByServiceId = createAsyncThunk(
  `${sliceName}/getServicesRowDataForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/service/service-property/version-history/get/${id}?${params || ""}`)
);

export const getServiceUnitsRowDataByServiceId = createAsyncThunk(
  `${sliceName}/getServiceUnitsRowDataByServiceId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/service/service-unit/version-history/get/${id}?${params || ""}`)
);

export const addServiceBulkUpdates = createAsyncThunk(
  `${sliceName}/addServiceBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

// slice
export const serviceSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default serviceSlice.reducer;
