import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import React from "react";
import { PrivateRoute } from "../layouts";

/* Route config */
import ProtectedRoutes from "./ProtectedRoutes";

// Page imports
import Login from "../views/login/Login";
import ErrorBoundary from "_components/ErrorBoundry/withErrorBoundary";
import LoginValidations from "views/login/LoginValidations";

const AppRoutes = () => {
  const location = useLocation();
  
  return (
    <ErrorBoundary state={location}>
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <ProtectedRoutes />
            </PrivateRoute>
          }
          errorElement={ErrorBoundary}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/login-validation" element={<LoginValidations />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;
