import { Button } from "@mui/material";
import { deleteLock } from "_store/scheduleLocking.slice";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const ScheduleLock = ({
    editURL = '',
    listingURL = '',
    refs,
    modelName = '',
    modelId = '',
    scheduled,
    lockTimeOut,
    lockedBy,
    stateParam,
    accessSetting
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { action } = useParams();

    const unLockEntry = (editRedirect) => {
        return dispatch(deleteLock({ modelName, modelId }))
            .then((res) => {
                if (!res.error) {
                    redirect(editRedirect)
                }
            })
            .catch(() => console.log("error"));
    }

    const redirect = (editRedirect) => {
        if (refs) {
            refs.current = true;
        }
        setTimeout(() => {
            if (editRedirect) {
                navigate(editURL, { state: stateParam });
            } else {
                navigate(listingURL);
            }
        }, 0)
    }

    return (
        <>
            <div className="formButtonsRow">
                {action === 'view' && accessSetting?.canEdit && !(lockedBy) && <Button
                    onClick={() => redirect(true)}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    size="large"
                >
                    Edit
                </Button>}
                {(lockedBy) && accessSetting?.canUnlock && <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    size="large"
                    onClick={() => unLockEntry(false)}
                >
                    Unlock
                </Button>
                }
                {(lockedBy) && accessSetting?.canUnlock && accessSetting?.canEdit && <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    size="large"
                    onClick={() => unLockEntry(true)}
                >
                    Unlock and Edit
                </Button>
                }
            </div>
            <div className="d-flex justify-content-center">
                {scheduled?.updatedBy && scheduled?.scheduleTime && <>
                    {`Scheduled By ${scheduled?.updatedBy ?? '-'} on ${dayjs(scheduled?.scheduleTime)}`}
                </>}
                {lockedBy && lockTimeOut && <>
                    {`Locked By ${lockedBy ?? "-"} on ${dayjs(lockTimeOut)}`}
                </>}
            </div>
        </>
    );
}

export default ScheduleLock