import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ActionDialog = (props) => {
  const {
    description,
    onCloseModal,
    openModal,
    title,
    actionType,
    actionButton,
    actionButtonSecondary,
    onAction,
    params,
    children,
    modalValue,
    fullWidth = false,
    isCloseButton = false,
    isDisabled = false,
    showCloseIcon = true,
    className = '',
    isLoader
  } = props;
  const [show, setShow] = useState(false);
  const [paramData, setParamData] = useState(false);
  const [type, setType] = useState(params?.type);

  useEffect(() => {
    if (title || params) {
      setShow(true);

      params?.type ? setType(params?.type) : setType("confirm");
    }
  }, [title, params]);

  useEffect(() => {
    if (type === "confirm") {
      let paramsData = {
        ...params,
        action: actionButton,
      };
      setParamData(paramsData);
    }
  }, [type, params, actionButton]);

  const handleSubmit = (actionName) => {
    if (type === "confirm") {
      onAction(paramData);
    } else {
      onAction({
        ...params,
        selection: modalValue,
        action: actionName,
      });
    }
  };

  const checkNullValue = (details) => {
    const emptykey = Object.keys(details).find(
      (key) => details[key] === "" || details[key] === null
    );
    return emptykey ? true : false;
  };
  return (
    <>
      {show && (
        <Dialog
          open={openModal}
          onClose={onCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={fullWidth}
          maxWidth={fullWidth && "md"}
          className={className?? ''}
        >
          <DialogTitle id="alert-dialog-title">
            {title}
           {showCloseIcon && <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 0,
                top: -4,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>}
          </DialogTitle>
          <DialogContent style={{ minWidth: 300 }}>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
            {children}
          </DialogContent>
          <DialogActions className="pr-4 pl-4 pb-3 pt-3 border-top justify-content-center">
            {type !== "htmlOnly" && actionButton && (
              <Button
                disabled={
                  isDisabled || isLoader ||
                  (type === "selectModal" || type === "inputModal"
                    ? typeof modalValue !== "string" || modalValue === ""
                    : false) ||
                  (type === "objectModal" ? checkNullValue(modalValue) : false)
                }
                onClick={() => handleSubmit(actionButton)}
                variant="contained"
                color={actionType || "primary"}
              >
                {actionButton}
              </Button>
            )}
            {type !== "htmlOnly" && actionButtonSecondary && actionButton && (
              <Button
                disabled={
                  isDisabled ||
                  (type === "selectModal" || type === "inputModal"
                    ? typeof modalValue !== "string" || modalValue === ""
                    : false) ||
                  (type === "objectModal" ? checkNullValue(modalValue) : false)
                }
                onClick={() => handleSubmit(actionButtonSecondary)}
                variant="contained"
                color={actionType || "primary"}
              >
                {actionButtonSecondary}
              </Button>
            )}
            {type !== "htmlOnly" && isCloseButton && actionButton && (
              <Button onClick={onCloseModal} variant="contained" color="error">
                Close
              </Button>
            )}
            {type === "htmlOnly" && actionButton && (
              <Button
                onClick={onCloseModal}
                variant="contained"
                color={actionType || "primary"}
              >
                OK
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ActionDialog;
