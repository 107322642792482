import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";

// Forms
import { Formik } from 'formik';
import * as Yup from 'yup';

//Components
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

// Lodash
import { isEmpty } from "lodash";

// Store
import { login, loginWithToken } from '_store/auth.slice';

const Login = () => {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const loginStatus = searchParams.get("action") ?? "";
    const loginToken = searchParams.get("token") ?? "";

    useEffect(() => {
        if(loginStatus==='GoogleAuthenticationSuccessful' && loginToken){
            return dispatch(loginWithToken(loginToken))
        }
        // redirect to Dashboard if already logged in
        else if(authUser) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Valid email is needed').required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    const onSubmit = ({ email, password }) => {
        return dispatch( login({ email, password }));
    }

    const googleLogin = () => {
        let callbackUrl = `${window.location.origin}/login-validation`
        let apirUrl = process.env.REACT_APP_API_URL;
        window.location.href = `${apirUrl}/user/login?url=${callbackUrl}`;
    }

    return (
        <div className='container-fluid align-items-center d-flex login-page'>
            <div style={{ maxWidth: "400px", margin:"3rem auto"}}>
                <div className="card">
                    <h4 className="card-header">Login</h4>
                    <div className="card-body">
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        error = { Boolean(errors.email && touched.email) }
                                        helperText={Boolean(errors.email && touched.email) && errors.email}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="password"
                                        id="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        autoComplete="current-password"
                                        value={values.password}
                                        error = { Boolean(errors.password && touched.password) }
                                        helperText={Boolean(errors.password && touched.password) && errors.password}
                                    />

                                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} size="large" disabled={isSubmitting || !isEmpty(errors)}>
                                        Submit
                                    </Button>
                                </form>
                            )}
                        </Formik>

                        <>
                            <Divider/>
                            <Button id="googleSignInButton" className="g-button" fullWidth variant="contained" color='default' sx={{ mt: 3, mb: 2 }} size="large" onClick={()=>googleLogin()}>
                                <img
                                className="g-logo"
                                src="/assets/images/google.png"
                                alt="Google Logo"
                                />
                                <p className="g-text">Sign in with Google</p>
                            </Button>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;