import * as React from 'react';

const InnerLoader = ({
  isBlockLoader = false
}) => {
  const className = isBlockLoader ? "block-loader" : "";
  return (
    <div className={`loader overlay ${className}`}><div className="spinner-border spinner-border-sm"></div></div>
  );
}

export default InnerLoader;
