import Loading from "layouts/Loading";
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// JSON import
import data from "./routes.json";

// Component imports
import ComponentName from "./ComponentName";

const buildRoutes = (data) => {
  return (
    <>
      {data.map((item, itemIndex) => (
        <Route path={item.link} key={`route-${itemIndex + 1}`}>
          {item.subRoutes && item.subRoutesRedirect ? (
            <Route
              index
              element={
                <Navigate
                  replace
                  to={(item.subRoutes && item?.subRoutes[0].link) || item.link}
                />
              }
            />
          ) : (
            <Route index element={<ComponentName name={item.component} />} />
          )}
          {item.subRoutes && buildRoutes(item.subRoutes)}
        </Route>
      ))}
    </>
  );
};

export const routes = data;

const ProtectedRoutes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {buildRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Suspense>
  );
};

export default ProtectedRoutes;
