import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "translationsData";

// APIs

//Users
export const getAllTranslations = createAsyncThunk(
  `${sliceName}/getAllTranslations`,
  async () => await fetchWrapper.get(`${baseUrl}/translate/getAll?filter=`)
);

// slice
export const translationsSlice = createSlice({
  name: sliceName,
  initialState: [],
  extraReducers: {
    //users
    [getAllTranslations.fulfilled]: (state, action) => {
      let orderedTranslations =  !action.payload?.error ? orderBy(action.payload, "sequence", "asc") : [];
      return orderedTranslations;
    },
    // [getAllTranslations.pending]: (state, action) => {
    //   return { loading: true };
    // },
    // [getAllTranslations.rejected]: (state, action) => {
    //   return { error: action.error };
    // },
  },
});

export default translationsSlice.reducer;
