import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history, fetchWrapper } from '_utils';

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const name = "auth";

// APIs
export const login = createAsyncThunk(
    `${name}/login`,
    async ({ email, password }) => await fetchWrapper.post(`${baseUrl}/user/login`, { email, password })
);

export const getRefreshToken = createAsyncThunk(
    `${name}/getRefreshToken`,
    async (token) => await fetchWrapper.post(`${baseUrl}/user/refreshJwt`, { refreshJwt: token })
);

function createInitialState() {
  const userToken = localStorage.getItem('userToken');
  let userData = null
  if(userToken && userToken?.split('.')?.[1] && atob){
      userData = JSON.parse(atob(userToken?.split('.')[1]))
  }
  return {
      // initialize state from local storage to enable user to stay logged in
      user:  userData, //JSON.parse(localStorage.getItem('userToken')),
      error: null
  }
}

// slice
export const authSlice = createSlice({
    name: name,
    initialState: createInitialState(),
    reducers: {
        logout: (state, action) => {
            localStorage.removeItem('userToken');
            history.navigate('/login');
            return {
                ...state,
                user: null,
                error: null
          };
        },
        refreshToken: (state, action) => {
            const user = action.payload;
            localStorage.setItem('userToken', user);
            state.user = user.split('.')?.[1] && atob ? JSON.parse(atob(user.split('.')[1])) : null;
        },
        setLocalUser: (state, action) => {
          const userData = {...state.user}
          return {
            ...state,
            user: {...userData, ...action.payload},
          };
        },
        loginWithToken: (state, action) => {
          localStorage.setItem('userToken', action.payload);
          state.user = action.payload?.split('.')?.[1] && atob ?  JSON.parse(atob(action.payload.split('.')[1])) : null;
          const { from } = history.location.state || { from: { pathname: '/' } };
          history.navigate(from);
        }
      },
    extraReducers: {
      [login.fulfilled]: (state, action) => {
        const user = action.payload.token;
        localStorage.setItem('userToken', user);
        state.user = user?.split('.')?.[1] && atob ? JSON.parse(atob(user.split('.')[1])) : null;
        // get return url from location state or default to Dashboard page
        const { from } = history.location.state || { from: { pathname: '/' } };
        history.navigate(from);
      },
      [login.pending]: (state, action) => {
        state.error = null;
      },
      [login.rejected]: (state, action) => {
        state.error = action.error;
      },
      [getRefreshToken.fulfilled]: (state, action) => {
        const user = action.payload.accessToken;
        localStorage.setItem('userToken', user);
        state.user =  user?.split('.')?.[1] && atob ? JSON.parse(atob(user.split('.')[1])) : null;
      },
    },
  });

// Exports
export const { logout, refreshToken, loginWithToken, setLocalUser } = authSlice.actions;
export default authSlice.reducer;

