import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { store } from "./_store";
import App from "./App";
import "./index.scss";
import { ThemeProvider } from "@emotion/react";

const themeOptions = createTheme({
  palette: {
    type: "light",
    default: {
      main: "#ffffff",
    },
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#0e61ff",
    },
    success: {
      main: "#558b2f",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: "Inter, Arial",
    fontSize: 14,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "12px 14px",
          height: "20px"
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 6,
        },
      },
    },
    MuiFormControl: {
      color: "#f00",
      styleOverrides: {
        root: {
          marginTop: 0
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "translate(14px, 10px) scale(1)",
        },
        shrink: {
          transform: "translate(14px, -8px) scale(0.7)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          paddingLeft: "15px",
          paddingRight: "15px",
        },
        default: {
          backgroundColor: "#fff",
        },
        outlined: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
        },
      },
    },
    MuiDataGrid: {
      columnsContainer: {
        backgroundColor: "#fff",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "5px 55px 4px 14px !important",
          },
          "& .MuiAutocomplete-tag": {
            height: "29px !important",
          },
          "& .MuiChip-root": {
            height: "29px !important",
          },
        },
      },
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={themeOptions}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);
