/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Field } from "formik";
import BlockHeading from "_components/common/BlockHeading";
import CustomizedSelectForFormik from "_components/CustomizedSelectForFormik";
import { getFeatureGroupsForCollection } from "_store/merchandising.slice";
import { useDispatch } from "react-redux";
import { MenuItem, TextField } from "@mui/material";

const FeatureGroupItem = ({
  index,
  value,
  formOption,
  onBlur,
  onChange,
  dragHandleProps,
  mediaData,
  isLast,
  disabled = false,
  collectionList,
  collections,
  setFieldValue,
  removeElement
}) => {
  const [featureGroupdata, setFeatureGroupData] = useState([])
  const namePrefix = `${formOption.name}[${index}]`;
  const dispatch = useDispatch()
  const collectionName = collectionList?.find((data) => data?.value === value?.collectionId)

  const handleRemove = () => {
    removeElement(index)
    // formOption.remove(index)
  }
  useEffect(() => {
    if (value.collectionId) {
      let param = `collectionId=${value.collectionId}`;
      dispatch(getFeatureGroupsForCollection(param)).then((response) => {
        if (response?.payload) {
          const featuresOptionDataResponse = response?.payload?.collectionFeatureGroupMaps?.map((i) => ({
            value: i?.featureGroup.id,
            label: i?.featureGroup?.name || i?.featureGroup.id,
          }));
          setFeatureGroupData(featuresOptionDataResponse);
        }
      });
    }
  }, [value?.collectionId]);

  const handleMoveElement = (type) => {
    let toIndex = index + 1;
    if (type === "up") {
      toIndex = index - 1;
    }
    value.sequence = toIndex + 1;
    formOption.move(index, toIndex);
  }

  return (
    <>
      <BlockHeading
        index={index}
        isLast={isLast}
        prevSequence={value.sequence}
        dragHandleProps={dragHandleProps}
        onMoveElement={handleMoveElement}
        disabled={disabled}
        onRemove={handleRemove}
      >
        <div className="row row-sm">
          <div className="col-sm-12 col-md-4 mb-3">
            <h4>Collection Name</h4>
            <TextField
              label="Collection Name"
              value={collectionName?.label || ""}
              fullWidth
              disabled={true}
            />
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <h4>Feature Group</h4>
            <Field
              labelid={namePrefix ? `${namePrefix}.featureGroupId` : "featureGroupId"}
              id={namePrefix ? `${namePrefix}.featureGroupId` : "featureGroupId"}
              name={namePrefix ? `${namePrefix}.featureGroupId` : "featureGroupId"}
              label="Select Features Group"
              onBlur={onBlur}
              onChange={onChange}
              value={value.featureGroupId || ""}
              placeholder="Select Features Group"
              component={CustomizedSelectForFormik}
              readOnly={disabled}
            >
              {
                Array.isArray(featureGroupdata) && featureGroupdata.length > 0 && (
                  featureGroupdata?.map((item) => {
                    return (
                      <MenuItem key={`${item?.value}-feat`} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    )
                  }
                  )
                )}
            </Field>
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <h4>Media Group</h4>
            <Field
              labelid={namePrefix ? `${namePrefix}.mediaTypeGroupId` : "mediaTypeGroupId"}
              id={namePrefix ? `${namePrefix}.mediaTypeGroupId` : "mediaTypeGroupId"}
              name={namePrefix ? `${namePrefix}.mediaTypeGroupId` : "mediaTypeGroupId"}
              label="Select Media Group"
              onBlur={onBlur}
              onChange={onChange}
              value={value.mediaTypeGroupId || ""}
              placeholder="Select Media Group"
              component={CustomizedSelectForFormik}
              readOnly={disabled}
            >
              {
                Array.isArray(mediaData) && mediaData.length > 0 && (
                  mediaData?.map((item) => {
                    return (
                      <MenuItem key={`${item?.value}-feat`} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    )
                  }
                  )
                )}
            </Field>
          </div>
        </div>
      </BlockHeading>
    </>
  );
}

export default FeatureGroupItem;