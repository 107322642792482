import { Button, IconButton, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createAlert } from "_store/alert.slice";
import { addWithSchedule, deleteLock, editSchedule } from "_store/scheduleLocking.slice";
import { scheduleValidation } from "_utils";
import dayjs from "dayjs";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ErrorMessage from "./ErrorMessage";

export const ScheduleModal = ({
    name,
    modelName,
    listingURL,
    values,
    disabled,
    redirectBack,
    setFieldValue,
    propertyTypeByid,
    accessSetting
}) => {
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(!open);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { id, action } = useParams();
    const [searchParams] = useSearchParams();
    const scheduleClick = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setFieldValue('scheduleTime', null)
        setOpen(false);
    }

    const submitSchedule = (schedule) => {
        setIsSubmitting(!isSubmitting)
        delete values.schedule
        if (!schedule?.scheduleTime) {
            schedule.scheduleTime = dayjs(new Date(+new Date() + 86400000))
        }
        const formData = {
            modelName,
            ...schedule,
            modelData: values,
        }
        if (modelName?.toLowerCase()?.includes('properties') && propertyTypeByid) {
            formData.modelData.propertyTypeId = propertyTypeByid
        }
        formData.action = action === 'edit' ? 'update' : 'add'
        if (action === 'edit') {
            formData.modelId = id
        }
        if (schedule?.id) {
            return dispatch(editSchedule({ id: schedule?.id, formData })).then((res) => {
                if (!res.error) {
                    dispatch(
                        createAlert({
                            alertType: "success",
                            msg: `${schedule.name} updated successfully`,
                            timeout: 5000,
                        })
                    );
                    navigate('/pim/scheduled');
                }
                setIsSubmitting(!isSubmitting)
            });
        } else {
            return dispatch(addWithSchedule(formData)).then((res) => {
                if (!res.error) {
                    dispatch(
                        createAlert({
                            alertType: "success",
                            msg: `${schedule.name} Added successfully`,
                            timeout: 5000,
                        })
                    );
                    redirectBack("update");
                }
                setIsSubmitting(!isSubmitting)
            });
        }

    }

    const unLockEntry = () => {
        if (searchParams.get("listPage") === 'scheduleEdit') {
            navigate(-1);
            return;
        } else {
            return dispatch(deleteLock({ modelName, modelId: values.objectId }))
                .then((res) => {
                    if (!res.error) {
                        setTimeout(() => {
                            navigate(listingURL);
                        }, 0)
                    }
                })
                .catch(() => console.log("error"));

        }
    }

    return (
        <>
            {action !== 'view' && accessSetting?.canSchedule && <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                onClick={() => scheduleClick()}
                disabled={disabled}
            >
                {`Schedule ${action === "edit" ? "Update" : "Save"}`}
            </Button>}
            {action === 'edit' && (values?.lockedBy || values?.schedule) && <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                size="large"
                onClick={() => unLockEntry()}
            >
                Cancel
            </Button>
            }
            <Modal open={open} onClose={handleClose}>
                <div className="schedule-popup">
                    <Formik
                        initialValues={values}
                        validationSchema={scheduleValidation}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            dirty,
                        }) => {
                            return (
                                <form className="w-100">
                                    <div className="col-sm-12 header">
                                        <h2>{name}</h2>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            className="btn-close"
                                            sx={{
                                                top: -4,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                        >
                                            <span className="material-icons">close</span>
                                        </IconButton>
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <h4>Name<sup className="required">*</sup></h4>
                                        <TextField
                                            id='schedule.name'
                                            label='Name'
                                            name='schedule.name'
                                            value={values?.schedule?.name}
                                            margin="normal"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(errors?.schedule?.name)}
                                        />
                                        {errors?.schedule?.name ? (
                                            <ErrorMessage error={errors?.schedule?.name} />
                                        ) : null}
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <h4>Description<sup className="required">*</sup></h4>
                                        <textarea
                                            id="schedule.description"
                                            rows={4}
                                            aria-label="maximum height"
                                            label="Description"
                                            className="w-100"
                                            name="schedule.description"
                                            value={values?.schedule?.description}
                                            placeholder="Description"
                                            onChange={handleChange}
                                            error={Boolean(errors?.schedule?.description)}
                                            style={{ resize: 'none' }}
                                        />
                                        {errors?.schedule?.description ? (
                                            <ErrorMessage error={errors?.schedule?.description} />
                                        ) : null}
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <h4 className="m-0 mt-1">
                                            Start Date<sup className="required">*</sup>
                                        </h4>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                sx={{
                                                    width: "100%",
                                                    ".MuiOutlinedInput-root": {
                                                        borderRadius: "2px",
                                                    },
                                                }}
                                                value={values?.schedule?.scheduleTime ? dayjs(values?.schedule?.scheduleTime) : dayjs(new Date(+new Date() + 86400000))}
                                                onChange={(newValue) => setFieldValue("schedule.scheduleTime", newValue)}
                                                minDateTime={dayjs()}
                                            />
                                        </LocalizationProvider>
                                        {errors?.schedule?.scheduleTime ? (
                                            <ErrorMessage error={errors?.schedule?.scheduleTime} />
                                        ) : null}
                                    </div>
                                    <div className="col-sm-10 mb-2 d-flex justify-content-around px-5">
                                        <Button
                                            sx={{ mt: 3, mb: 2 }}
                                            size="large"
                                            onClick={() => handleClose()}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            onClick={() => submitSchedule(values.schedule)}
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            size="large"
                                            disabled={!dirty || !isEmpty(errors) || !isSubmitting || disabled}
                                        >
                                            {values?.schedule?.id ? "Update" : "Save"}
                                        </Button>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            </Modal>
        </>
    );
}

export default ScheduleModal