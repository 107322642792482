import React from "react"
import ErrorIcon from "./ErrorIcon"
import classes from "./ErrorFallbackUI.module.css"

const ErrorFallBackUI = (props) => {
  return (
    <div className={classes.errorfallbackui}>
      <div>
        <div className={classes.title}>
          <ErrorIcon />
          Oops! 
        </div>
        <div>
          <div className={classes.subtitle}>
          Something went wrong, try refreshing the page.
          </div>
          <div className={classes.retrysteps}>
            If issue still persist, kindly try again later or browse other features.
          </div>
        </div>
      </div>
      {/* <div>
        
      </div> */}
    </div>
  )
}
export default ErrorFallBackUI
