import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; 

//Components
import Button from "@mui/material/Button";

// Store
import { getAllRoles, getAllUsers } from "_store/access.slice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user: authUser } = useSelector((x) => x.auth);
  const [ roles, setRoleData] = useState();
  const [ users, setUserData] = useState();

  useEffect(() => {
    dispatch(getAllRoles()).then((response) => {
      setRoleData(response?.payload);
    });
    dispatch(getAllUsers()).then((response) => {
      setUserData(response?.payload);
    });
  }, [dispatch]);
  const getGreeting = () => {
    let today = new Date()
    let curHr = today.getHours()
    if (curHr < 12) {return 'Good Morning'} 
    else if (curHr < 18) {  return 'Good Afternoon'} 
    else {return 'Good Evening'}
  }

  return (
    <div>
      <div className="headerTitleRow">
        <h1>{ getGreeting()}, {authUser?.firstname}!</h1>
      </div>
      <div className="row">
        <div className="col">
          {users?.length >= 0 && (
            <div>
              <div className="card w-100 rounded-lg">
                <div className="card-body d-flex w-100 justify-content-between  align-items-center">
                    <div>
                      <span className="badge rounded-pill bg-light">Access</span>
                      <h2 className="font-weight-bold font-size-h4 mb-2 text-primary">Users</h2>
                      <p>Manage user data</p>
                      <Button type="button" onClick={() => navigate("/access/users")} variant="outlined" color="secondary"><span className="material-icons mr-1">chevron_right</span> View</Button>
                    </div>
                    <div className="h1 font-weight-bold  p-2">{users?.length}</div>
                </div>
              </div>
            </div>
          )}
          {users?.loading && (
            <div className="spinner-border spinner-border-sm"></div>
          )}
          {users?.error && (
            <div className="text-danger">
              Error loading users: {users?.error?.message}
            </div>
          )}
        </div>
        <div className="col">
          {roles?.length >= 0 && (
            <div>
              <div className="card w-100 rounded-lg">
                <div className="card-body d-flex w-100 justify-content-between align-items-center">
                  <div>
                    <span className="badge rounded-pill bg-light">Access</span>
                    <h2 className="font-weight-bold font-size-h4 mb-2 text-warning">Roles</h2>
                    <p>Manage roles</p>
                    <Button type="button" onClick={() => navigate("/access/roles")} variant="outlined" color="warning"><span className="material-icons mr-1">chevron_right</span> View</Button>
                  </div>
                  <div className="h1 font-weight-bold p-2">{roles?.length}</div>
                </div>
              </div>
            </div>
          )}
          {roles?.loading && (
            <div className="spinner-border spinner-border-sm"></div>
          )}
          {roles?.error && (
            <div className="text-danger">
              Error loading roles: {roles?.error?.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
