/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Popover,
  Box,
  Checkbox,
  Divider,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { toggleItemInArray } from "_utils/commonFunctions";
import { setFieldSelectedLanguage } from "_store/common.slice";

export const LanguageSelector = ({field,translationsOpen,translationsId,setTranslationsPopover ,translationsPopover}) => {
  const dispatch = useDispatch();
  const { fieldSelectedLanguages } = useSelector((x) => x.commonData);
  let translations = useSelector((x) => x.translations);
  translations = translations?.loading ? []  : translations;
  const [selectedTranslatedValue, setSelectedTranslatedValue] = useState([]);
  const handleTranslateSelectAll = () => {
    const allCodes = translations?.map((trans) => trans.translate_id);
    const isSelectAll = allCodes.length === selectedTranslatedValue.length;
    setSelectedTranslatedValue(isSelectAll ? [allCodes[0]] : allCodes);
  };

  // handles what happens when translation is changed
  const handeTranslateOnChange = (translateValue) => {
    const newTranslationArr = toggleItemInArray(
      selectedTranslatedValue,
      translateValue
    );
    if (newTranslationArr.length >= 1) {
    dispatch(setFieldSelectedLanguage({field: field, selectedLanguages: [...newTranslationArr], translations}));
    setSelectedTranslatedValue(newTranslationArr);
    }
    else {
      setSelectedTranslatedValue([translateValue]);
    }
  };

  useEffect(()=> {
    if(selectedTranslatedValue.length === 0){
        setSelectedTranslatedValue(fieldSelectedLanguages?.[field]  ?  [...(fieldSelectedLanguages?.[field] ?? []) ] : [])
    }
  }, [fieldSelectedLanguages?.[field], selectedTranslatedValue.length])

  useEffect(()=>{
    dispatch(setFieldSelectedLanguage({field: field, selectedLanguages: [...selectedTranslatedValue], translations}));
  }, [selectedTranslatedValue.length, translations.length])

  return (
      <>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          id={translationsId}
          open={translationsOpen}
          anchorEl={translationsPopover}
          onClose={() => setTranslationsPopover(null)}
          style={{
            left: -100,
          }}
        >
          {/* render all the translations possible */}

          <Box pr={2}>
            {translations?.length > 2 && (
              <>
                <div className="d-flex justify-content-start align-items-center">
                  <Checkbox
                    checked={
                      selectedTranslatedValue.length === translations.length
                    }
                    onChange={handleTranslateSelectAll}
                    indeterminate={
                      selectedTranslatedValue.length > 1 &&
                      selectedTranslatedValue.length !== translations.length
                    }
                  />
                  <span>Select All</span>
                </div>
                <Divider className="ml-3" />
              </>
            )}

            {translations?.map((trans, index) => {
              return (
                <div
                  className="d-flex justify-content-start align-items-center"
                  key={trans.translate_id}
                >
                  <Checkbox
                    checked={selectedTranslatedValue.includes(trans.translate_id)}
                    disabled={
                      (selectedTranslatedValue.length === 1 &&
                      selectedTranslatedValue[0] === trans.translate_id) || (selectedTranslatedValue.includes(trans.translate_id) && index === 0)
                    }
                    onChange={() => handeTranslateOnChange(trans.translate_id)}
                  />
                  <span>{trans.name}</span>
                </div>
              );
            })}
          </Box>
        </Popover>
      </>
  );
};
