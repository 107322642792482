import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "incentiveCalculator";

export const getAllVendors = createAsyncThunk(
  `${sliceName}/getAllVendors`,
  async (params) => await fetchWrapper.get(`${baseUrl}/vendor/dropdown/getAll?${params || ""}`)
);


// slice
export const priceCalculatorSlice = createSlice({
  name: sliceName,
  initialState: {
    calculatorResult: null,
  },
  reducers: {
    setIncentiveCalculateResult: (state, action) => {
      return { ...state, calculatorResult: action.payload };
    },
    setIncentiveCalculatorFormData: (state, action) => {
    return { ...state, calculatorFormData: action.payload };
  },
  },
});

export const { setIncentiveCalculateResult, setIncentiveCalculatorFormData } = priceCalculatorSlice.actions;

export default priceCalculatorSlice.reducer;
