/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

const Footer = () => {
  const {apiVersion, uiVersion} = useSelector((x) => x.commonData);
  const [ uiLocalVersion, setUiLocalVersion] = useState();
  const [ openModal, setOpenModal] = useState(false);

  useEffect(()=> {
    if(!uiLocalVersion && uiVersion){
      setUiLocalVersion(uiVersion)
    }
    else if(uiLocalVersion && uiLocalVersion !== uiVersion){
      setOpenModal(true);
    }
  }, [apiVersion, uiVersion])

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    else if (reason === "escapeKeyDown") {
      return false;
    }

    else {
      setOpenModal(false);
      window.location.reload(true);
    }
    
  };

  return (
    <footer className="app-footer">
      { (apiVersion || uiVersion) &&
        <div className="app-version">
          {apiVersion && <div>API: {apiVersion}</div>}
          {uiVersion && <div>UI: {uiVersion}</div>}
        </div>
      }
      <Dialog
        className="default-modal"
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle id="alert-dialog-title">
          <div  className="text-info d-flex align-items-center">
            <span class="material-icons mr-1">info</span> 
            <span>New App Version Available..!!</span>
          </div>
        </DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <DialogContentText id="alert-dialog-description">
            Kindly refresh page to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="pr-4 pl-4 pb-3 pt-3 border-top justify-content-center">
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color={"secondary"}
          >
            OKAY
          </Button>
        </DialogActions>
      </Dialog>
    </footer>
  );
};

export default Footer;
