/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "_utils";
import { Header, AlertUI, MenuOverlay, SideBar } from "layouts";

// routes
import AppRoutes from "./routes/AppRoutes";
import { getAllTranslations } from "_store/translations.slice";
import {
  getPropertyTypes,
  setDefaultLanguage,
  getApiVersion,
  getUiVersion,
} from "_store/common.slice";
import UpdateUpStreamModal from "_components/UpdateUpStreamModal";
import Footer from "layouts/Footer";
import { getRefreshToken, logout } from "_store/auth.slice";

const App = () => {
  const dispatch = useDispatch();
  history.navigate = useNavigate();
  history.location = useLocation();
  const translations = useSelector((x) => x.translations);
  const { user } = useSelector((x) => x.auth);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const ref = useRef(true);
  /* First render */
  useEffect(() => {
    const firstRender = ref.current;
    const token = localStorage.getItem("userToken");
    if (firstRender && user && token) {
      ref.current = false;
      dispatch(getAllTranslations()).catch(() => dispatch(logout()));
      dispatch(getPropertyTypes());
      getVersions();
      setInterval(() => {
        getVersions();
      }, 20000);
      setInterval(() => {
        triggerRefreshToken();
      }, 599900);
    }
  }, [user]);

  const getVersions = () => {
    dispatch(getApiVersion());
    dispatch(getUiVersion());
  };

  const triggerRefreshToken = () => {
    let token = localStorage.getItem("userToken");
    dispatch(getRefreshToken(token));
  };

  useEffect(() => {
    if (Array.isArray(translations) && translations.length > 0) {
      dispatch(
        setDefaultLanguage(translations.find((item) => item.sequence === 1))
      );
    }
  }, [translations, dispatch]);

  return (
    <>
      <div className="app-container">
        <Header navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
        <MenuOverlay navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />

        <div className="page-wrapper container-fluid">
          <SideBar
            navbarOpen={sidebarOpen}
            setNavbarOpen={setSidebarOpen}
            pageLocation={history.location.pathname}
          />
          <div className="page-content">
            <AppRoutes />
          </div>
        </div>
        <Footer />
      </div>
      <UpdateUpStreamModal />
      <AlertUI></AlertUI>
    </>
  );
};

export default App;
