import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth.slice";
import alertsReducer from "./alert.slice";
import metalReducer from "./pim/metal.slice";
import appconfigReducer from "./appconfig.slice";
import stoneReducer from "./pim/stone.slice";
import translationsReducer from "./translations.slice";
import commonDataReducer from "./common.slice";
import serviceReducer from "./pim/service.slice";
import addOnReducer from "./pim/addOn.slice";
import componentReducer from "./pim/family.slice";
import upStreamReducer from "./upStream.slice";
import merchandisingReducer from "./merchandising.slice";
import priceCalculator from "./pricing/priceCalculator.slice";
import incentiveCalculator from "./incentives/incentiveCalculator.slice";

import vendorsSlice from "./vendors.slice";
import VendorConfig from "./vendorConfig.slice";
import cronSlice from "./cron.slice";

const rootReducer = combineReducers({
  metal: metalReducer,
  stone: stoneReducer,
  service: serviceReducer,
  addOn: addOnReducer,
  component: componentReducer,
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertsReducer,
    pim: rootReducer,
    commonData: commonDataReducer,
    translations: translationsReducer,
    appconfig: appconfigReducer,
    upStream: upStreamReducer,
    merchandising: merchandisingReducer,
    pricingCalculator: priceCalculator,
    incentiveCalculator: incentiveCalculator,
    vendor:vendorsSlice,
    vendor_config:VendorConfig,
    cron:cronSlice
  },
});
