import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <div className='loader'>
      <CircularProgress/>
    </div>
  );
}

export default Loading;
