import React from "react";
import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";

const MenuOverlay = ({ navbarOpen, setNavbarOpen }) => {
  const authUser = useSelector((x) => x.auth.user);

  // only show nav when logged in
  if (!authUser) return null;

  return (
    <>
      {authUser && (
        <div id="mobile-menu-box" className={`${navbarOpen ? "open" : ""}`}>
          <button
            className="mobile-btn"
            type="button"
            onClick={() => {
              setNavbarOpen(!navbarOpen);
            }}
          >
            <span className="material-icons">diamond</span>
          </button>
          <nav id="mobileMenu" className={`${navbarOpen ? "open" : ""}`}>
            <div>
              <ul className="mr-auto navbar-nav">
                <li className="nav-item">
                  <NavLink
                    to="/dashboard"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/access"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Access
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/pim"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Products
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/pricing"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Pricing
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/customers"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Customers
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/orders"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/rules"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Rules
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/procurement"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Procurement
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/merchandising"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Merchandising
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/finance"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Finance
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/settings"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Settings
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/vendors"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    Vendors
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/shipment"
                    onClick={() => setNavbarOpen(false)}
                    className="nav-link"
                    activeclassname="active"
                  >
                    ShipMent
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default MenuOverlay;
