import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";

// Store
import InnerLoader from '_components/InnerLoader';
import { createAlert } from "_store/alert.slice";
import { loginWithToken } from '_store/auth.slice';

const LoginValidations = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector(x => x.auth.user);
    const [searchParams] = useSearchParams();
    const loginStatus = searchParams.get("action") ?? "";
    const errMsg = searchParams.get("errMsg") ?? "";
    const loginToken = searchParams.get("token") ?? "";

    useEffect(() => {
        if(loginStatus==='GoogleAuthenticationSuccessful' && loginToken){
            dispatch(loginWithToken(loginToken))
        }
        // redirect to Dashboard if already logged in
        else if(authUser) {
            navigate('/');
        }
        else{
            dispatch(createAlert({ alertType: "error", msg: errMsg || "Goolge Authentication Failed", timeout: 8000 }));
            navigate('/login');
        }
    }, [authUser, dispatch, errMsg, loginStatus, loginToken, navigate]);

    return (
        <div className='container-fluid align-items-center d-flex login-page'>
            <InnerLoader /> Validating User...
        </div>
    )
}

export default LoginValidations;