/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "@mui/material";
import { FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";
import FeatureGroupItem from "./FeatureGroupItem";

const FeaturesGroup = ({
  prefixName = "",
  onChange,
  onBlur,
  value,
  onReplaceGroupList,
  featuresGroupData,
  setFieldValue,
  mediaData,
  disabled = false,
  includeCollectionsItems,
  collectionListData,
  action
}) => {
  const [isedited, setIsedited] = useState(false)
  const [collectionFeature, setCollectionFeature] = useState([])
  let collectionList = value?.featureGroupMaps || [];
  const setGroupValue = useCallback(
    (val) => {
      const data = collectionList?.filter(item => includeCollectionsItems?.includes(item.collectionId));
      setCollectionFeature(data);
      if (typeof setFieldValue === "function") {
        if (includeCollectionsItems?.length < collectionList?.length) {
          setCollectionFeature(data);
        } else {
          setCollectionFeature(val);
        }
        setFieldValue("featureGroupMaps", val);
      }
      if (typeof onReplaceGroupList === "function") {
        if (includeCollectionsItems?.length < collectionList?.length) {
          setCollectionFeature(data);
        } else {
          setCollectionFeature(val);
        }
        onReplaceGroupList(val);
      }      
    },
    [setFieldValue, includeCollectionsItems],
  )

  useEffect(() => {
    if ((action === "edit" || action === "view") && isedited && collectionList?.length > 0) {
      setGroupValue(collectionList);
      setIsedited(!isedited)
    }
    else {
      if (Object.keys(includeCollectionsItems).length) {
        const newCollectionId = collectionList?.map(item => item.collectionId);
        const result = includeCollectionsItems.filter(id => !newCollectionId.includes(id));
        const data = result?.map((data, i) => {
          return ({
            collectionId: data,
            featureGroupId: "",
            mediaTypeGroupId: "",
            sequence: i + 1
          })
        })
        setGroupValue([...collectionList, ...data]);
      }
    }
  }, [includeCollectionsItems, setGroupValue, action]);

  const handleremoveArray = (subSeqindex) => {
    let array = JSON.parse(JSON.stringify(collectionFeature));
    const newItem = array[subSeqindex]; 
    const result = array?.filter(item => item?.collectionId !== newItem?.collectionId);
    setFieldValue(`featureGroupMaps`, result);
    setFieldValue('includeCollections', value?.includeCollections.filter(item => item !== newItem?.collectionId))
    setCollectionFeature(result);
  }

  return (
    <>
      <div className="form-group drag_and_drop_block">
        <Divider />
        <FieldArray
          name={"featureGroupMaps"}
          render={(formOption) =>
            collectionFeature?.map((blockValue, sIndex) =>
              <FeatureGroupItem
                prefixName={prefixName}
                key={`${sIndex}-collectionTypeId`}
                index={sIndex}
                formOption={formOption}
                featuresGroupData={featuresGroupData}
                value={blockValue}
                collections={value?.includeCollections}
                onBlur={onBlur}
                onChange={onChange}
                mediaData={mediaData}
                isLast={sIndex === (collectionFeature.length - 1)}
                disabled={disabled}
                collectionList={collectionListData}
                setFieldValue={setFieldValue}
                removeElement={handleremoveArray}
              />

            )
          }
        />
      </div>
    </>
  );
}

export default FeaturesGroup;
