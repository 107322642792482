import React from "react";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Box, Radio } from "@mui/material";

const TreeViewComponent = ({
  optionsData,
  expanded,
  selected,
  setExpanded,
  setSelected,
  heading,
}) => {
  const handleNodeSelect = (node) => {
    setSelected(node);
  };

  const renderTree = (nodes) => (
    <TreeView
      key={nodes?.id}
      defaultCollapseIcon={
        nodes?.navigationChildren?.length > 0 ? (
          <div className="material-icons">expand_more</div>
        ) : null
      }
      defaultExpandIcon={
        nodes?.navigationChildren?.length > 0 ? (
          <div className="material-icons">chevron_right</div>
        ) : null
      }
      onNodeToggle={handleToggle}
      expanded={nodes?.navigationChildren?.length > 0 ? expanded : []}
    >
      <TreeItem
        expandIcon={nodes?.navigationChildren?.length > 0 ? <div className="material-icons">chevron_right</div> : null}
        onClick={() => handleToggle(nodes?.id)}
        key={nodes?.id}
        nodeId={String(nodes?.id)}
        label={
          <div>
            <Radio
              checked={selected?.id === nodes?.id}
              onChange={() => handleNodeSelect(nodes)}
            />
            {nodes?.label}
          </div>
        }
      >
        <div className="tree-item-box">
          {Array.isArray(nodes?.navigationChildren)
            ? nodes.navigationChildren.map((nd) =>
              renderTree(nd)
            )
            : null}
        </div>
      </TreeItem>
    </TreeView >
  );

  const handleToggle = (nodeIds) => {
    setExpanded((exp) =>
      exp.includes(nodeIds)
        ? exp.filter((item) => item !== nodeIds)
        : [...exp, nodeIds]
    );
  };

  return (
    <>
      {heading && (
        <Box
          sx={{
            marginY: "0.5rem",
            fontWeight: "400",
            fontSize: "18px",
            marginTop: "20px",
          }}
        >
          {heading}
        </Box>
      )}
      {optionsData?.map((option, index) => (
        <div className="tree-item-box" key={`tree-${String(index + 1)}`}>{renderTree(option)}</div>
      ))}
    </>
  );
};
export default TreeViewComponent;
