/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import ActionDialog from "./ActionDialog";
import SelectModal from "./SelectModal";

const AutocompleteForFormik = ({ form, field, label, disableClearable = false, onChange, isImage, imageClass, isNoOptionsReq = true, disabled = false, ...props }) => {
  const { name, value, id } = field;
  const [selectedValue, setSelectedValue] = useState(null);
  const [modalState, setModalState] = useState({
    description: "",
    openModal: false,
    title: "",
    actionType: "",
    actionButton: "",
    actionSecondary: "",
    actionButtonSecondary: "",
    iscloseButton: false,
  });
  const [selectedStoreId, setSelectedStoreId] = useState('');

  const { setFieldValue, errors, touched, setTouched } = form;
  const valObj = Array.isArray(value) ?
    value.map(val => props?.options?.find(i => i.value === value)) :
    props?.options?.find(i => i.value === value);

  const newValOb = valObj ||
    props?.options?.find(i => i?.value === props?.value);

  useEffect(() => {
    setSelectedValue(newValOb)
  }, [newValOb])

  const pageValues = {
    name: valObj?.name,
    id: valObj?.id,
    templateBlockMaps: valObj?.templateBlockMaps,
    sections: valObj?.sections,
  };
  const handleChange = (_, newValue) => {
    let updatedValue;
    if (Array.isArray(newValue)) {
      updatedValue = [];
      newValue.forEach(item => (updatedValue.push(item.value)))
    }
    else {
      updatedValue = newValue?.value ?? newValue?.url ?? '';
    }
    setFieldValue(name, updatedValue)
    setSelectedValue(updatedValue)
    if (onChange && updatedValue) {
      onChange(updatedValue)
    }
  }

  const onModalValueSelection = (value) => {
    setSelectedStoreId(value)
  }

  const handleOpenModal = (
    description,
    title,
    action,
    actionButton,
    params,
    actionButtonSecondary,
    iscloseButton
  ) => {
    setModalState({
      description,
      openModal: true,
      title,
      actionType: action,
      actionButton,
      params: params,
      actionButtonSecondary,
      iscloseButton,
    });
  };
  const handleCreateNew = () => {
    handleOpenModal("", "CMS Mapping", "secondary", "", {
      type: "selectModal",
    });
  }

  const handleCloseModal = () => {
    setModalState({ openModal: false });
  };
  return (
    <>
      <Autocomplete
        className="d-flex align-items-center gap-3 page-select-wrapper"
        multiple={Array.isArray(valObj)}
        filterSelectedOptions
        name={name}
        key={valObj?.id}
        disabled={disabled}
        disableClearable={disableClearable || !selectedValue}
        id={props?.id || `${id}-label`}
        value={selectedValue}
        options={props.options}
        noOptionsText={isNoOptionsReq ? "Nothing selected" : ""}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box key={valObj?.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option?.label}
            {isImage && <img src={`http://nj-ui.ray.n7-sparks.n7net.in/${option?.value ?? option?.url}`} alt="image" className={`${imageClass ? imageClass : ''} image-dropdown`} />}
          </Box>
        )}
        onBlur={() => setTouched({ [name]: true })}
        isOptionEqualToValue={(option, value) => option === value || option.value === value}
        onChange={handleChange}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={label}
              onBlur={props?.handleBlur}
              onChange={props?.handleChange}
              required={props?.required}
              placeholder={(Array.isArray(valObj) && valObj?.length > 0) ? 'Add More' : 'Select'}
              error={Boolean(touched[name] && errors[name])}
              helperText={Boolean(errors[name] && touched[name]) && errors[name]}
            />
            {value && props?.viewButton && (
              props?.isPopup ? <Button variant="text" onClick={handleCreateNew}>{props?.viewButton}</Button>: <a target="_blank" href={`../../sections/${valObj?.id}/view?type=${valObj?.type}&listPage=view`} rel="noreferrer">{props?.viewButton}</a>)}
          </>
        )}

      />
      <ActionDialog {...modalState} onCloseModal={handleCloseModal} className="linked-page-modal">
        {modalState?.params?.type === 'selectModal' &&
          <SelectModal
            onSelection={onModalValueSelection}
            selection={selectedStoreId}
            viewLinkedOptions={pageValues}
            setSelected={''}
            dataLoading={false}
            selected={''}
            isDropdown={false}
            viewLinkedPages={true}
            page={props?.page}
          />
        }
      </ActionDialog>
    </>
  );
};

export default AutocompleteForFormik;
