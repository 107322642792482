import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { LanguageSelector } from "./LanguageSelector";
import ActionDialog from "_components/ActionDialog";
import SelectModal from "_components/SelectModal";
import { IconButton } from "@mui/material";
import { StateFormPopover } from "_components/forms/StateFormPopover";

const FloatingButton = ({
  id,
  handleModalAction,
  deleteAction,
  isDisableDelete,
  changeStateUrl,
  allowedActions = [], // "replan"
}) => {
  const [translationsPopover, setTranslationsPopover] = useState(null);
  const { action } = useParams();
  const [statesPopover, setStatesPopover] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalState, setModalState] = useState({
    description: "",
    openModal: false,
    title: "",
    actionType: "",
    actionButton: "",
  });

  const handleOpenModal = (
    description,
    title,
    action,
    actionButton,
    params
  ) => {
    setModalState({
      description,
      openModal: true,
      title,
      actionType: action,
      actionButton,
      params: params,
    });
  };

  const handleCloseModal = () => {
    setModalState({ openModal: false });
    setTranslationsPopover(null);
  };
  const handleMenuToggle = (event) => {
    setIsMenuOpen((prev) => !prev);
    if (isMenuOpen) {
      setTranslationsPopover(null);
    }
  };

  const handleTranslations = (event, columns) => {
    setTranslationsPopover(translationsPopover ? null : event.currentTarget);
  };

  const handleSTateToggle = (event, columns) => {
    setStatesPopover(statesPopover ? null : event.currentTarget);
  };

  const translationsOpen = Boolean(translationsPopover);
  const stateFormOpen = Boolean(statesPopover);

  const translationsId = translationsOpen ? "simple-popover-2" : undefined;

  const handleClick = (action) => {
    if (action === "Delete") {
      handleOpenModal(
        `Do you want to delete the ${deleteAction}`,
        `Delete ${deleteAction}`,
        "error",
        "Delete"
      );
    }
  };
  return (
    <div>
      <LanguageSelector
        translationsOpen={translationsOpen}
        translationsId={translationsId}
        setTranslationsPopover={(value) => setTranslationsPopover(value)}
        translationsPopover={translationsPopover}
        field={"forms"}
      />
      <StateFormPopover
        stateFormOpen={stateFormOpen}
        setStatesPopover={(value) => setStatesPopover(value)}
        statesPopover={statesPopover}
        changeStateUrl={changeStateUrl}
      />
      <div className={`form-tool-section ${isMenuOpen ? "active" : ""}`}>
        <IconButton
          aria-label={translationsId}
          onClick={handleMenuToggle}
          color={isMenuOpen ? "primary" : "default"}
          title={isMenuOpen ? "Close" : "Menu"}
        >
          {!isMenuOpen && <span className="material-icons">more_vert</span>}
          {isMenuOpen && (
            <span className="material-icons">keyboard_double_arrow_right</span>
          )}
        </IconButton>

        {isMenuOpen ? (
          <div className="floating-btton-list">
            {changeStateUrl && action === "edit" && !isDisableDelete && (
              <IconButton
                aria-label={"States"}
                onClick={handleSTateToggle}
                color={isMenuOpen ? "primary" : "default"}
                title="State"
              >
                <span className="material-icons">tune</span>
              </IconButton>
            )}
            <IconButton
              aria-label="Language"
              onClick={(event) => handleTranslations(event)}
              title="Choose Translations"
            >
              <span className="material-icons">language</span>
            </IconButton>

            {id && !isDisableDelete && (
              <>
                <IconButton
                  aria-label="Delete"
                  onClick={() => handleClick("Delete")}
                  title="Delete"
                >
                  <span className="material-icons">delete_outline</span>
                </IconButton>
              </>
            )}
            {action === "edit" && allowedActions.includes("replan") && (
              <>
                <IconButton
                  aria-label="Re-Plan"
                  onClick={() => handleClick({ action: "replan" })}
                  title="Re-Plan"
                >
                  <span className="material-icons">watch_later</span>
                </IconButton>
              </>
            )}
          </div>
        ) : null}
      </div>
      <ActionDialog
        {...modalState}
        onCloseModal={handleCloseModal}
        onAction={(data) => {
          setModalState((prev) => ({ ...prev, isDisabled: true }));
          handleModalAction(data);
        }}
      >
        {modalState?.params?.type === "selectModal" && <SelectModal />}
      </ActionDialog>
    </div>
  );
};

export default FloatingButton;
