export const toggleCheck = (data, parentIndex, isChecked = false) => {

    if (parentIndex === "all_child") {
        return data.map(i => {
            i.isChecked = isChecked;
            if (i?.children && i.children.length) {
                i.children = toggleCheck(i.children, parentIndex, isChecked);
            }
            return i
        })
    }

    let pIndex = parentIndex.shift();

    if (!pIndex && pIndex !== 0) {
        return data;
    }

    return data.map((item, index) => {
        const isSameItem = pIndex === index;
        if (isSameItem && !parentIndex.length) {
            item.isChecked = isChecked;
            // All children tick if parent check or un-checked
            parentIndex = "all_child";
        }

        if (item?.children && item.children.length && isSameItem) {
            item.children = [...toggleCheck(item.children, parentIndex, isChecked)];
            if (item.children.some(i => i.isChecked)) {
                item.isChecked = true;
            }
        }

        return item;
    });
}

const removeDuplicateFromUpstream = (data) => {
    Object.entries(data).map(([key, value]) => {
        if (Array.isArray(value)) {
            data[key] = [...new Set(value)];
        }
        return [key, value];
    });

    return data;
}

const flattenData = (array = [], parentObject = {}) => {
    const result = array.reduce((acc, item) => {
        if (!item.isChecked) {
            return acc;
        }

        if (!acc[item.key]) {
            acc[item.key] = [item.id]
        } else {
            acc[item.key].push(item.id)
        }

        if (item?.children && item.children.length) {
            acc = flattenData(item.children, acc);
        }
        return acc;
    }, parentObject);

    return result;
}

export const getFlattenData = (array = [], parentObject = {}) => {
    const result = flattenData(array, parentObject)
    return removeDuplicateFromUpstream(result);
}

export const toggleCheckAll = (data = [], isChecked = false) => {

    return data.map((item) => {
        item.isChecked = isChecked;

        if (item?.children && item.children.length) {
            item.children = [...toggleCheckAll(item.children, isChecked)];
        }

        return item;
    });
}
