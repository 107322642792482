import React, { Component } from "react"
import ErrorFallBackUI from "./ErrorFallbackUI"
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null}
    }

    componentDidUpdate = () => {
        if (this.props?.state?.pathname !== this?.state?.location) {
            this.setState({
                error: null,
                errorInfo: null,
                location: this.props.state.pathname
            })
        }
    }

    componentDidCatch = (error, errorInfo) => {
        if (this.props?.state?.pathname !== this?.state?.location) {
            this.setState({
                error: null,
                errorInfo: null,
                location: this.props.state.pathname 
            })
        } else {
            this.setState({
                error: error,
                errorInfo: errorInfo,
                location: this.props.state.pathname 
            })
        }
    }

    render() {
        if (this.state.errorInfo) {
            return <ErrorFallBackUI />
        }
        // Normally, just render children
        return this.props.children
    }
}