import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";


// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "scheduleLockingData";

// APIs
/* Schedule Locking */
export const addWithSchedule = createAsyncThunk(
  `${sliceName}/addWithSchedule`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/data-model-schedule/add`, values);
    return res || res === "" ? res : null;
  }
);

export const getScheduleRowData = createAsyncThunk(
  `${sliceName}/getScheduleRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/data-model-schedule/getAll${params ? '?' + params : ''}`)
);

export const getScheduleMetaData = createAsyncThunk(
  `${sliceName}/getScheduleMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/data-model-schedule/getMetadata${params ? '?' + params : ''}`)
);

export const getScheduleById = createAsyncThunk(
  `${sliceName}/getScheduleById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/data-model-schedule/get/${id}`)
);

export const editSchedule = createAsyncThunk(
  `${sliceName}/editSchedule`,
  async (values) => await fetchWrapper.post(`${baseUrl}/data-model-schedule/update/${values.id}`, values.formData)
);

export const deleteLock = createAsyncThunk(
  `${sliceName}/deleteLock`,
  async ({ modelId, modelName }) => await fetchWrapper.post(`${baseUrl}/lock-data-model/unlock/${modelId}/${modelName}`)
);

export const deleteSchedulePermanent = createAsyncThunk(
  `${sliceName}/deleteSchedulePermanent`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/data-model-schedule/delete-permanent/${id}`, { id })
);



/* Schedule Locking Version */

// slice
export const rulesSlice = createSlice({
  name: sliceName,
  initialState: {},
});


export default rulesSlice.reducer;
