import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "familyData";

// APIs
/*Family Units */
/* table Meta Data */
export const getFamilyTypesMetaData = createAsyncThunk(
  `${sliceName}/getFamilyTypesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/family/family-type/getMetadata`)
);


export const getAllFaimilyAllProperties = createAsyncThunk(
  `${sliceName}/getAllFaimilyAllProperties`,
  async (familyTypeId) =>
    await fetchWrapper.get(`${baseUrl}/family/family-type/${familyTypeId}/all-properties`)
);

export const getFamilyTypesRowData = createAsyncThunk(
  `${sliceName}/getFamilyTypesRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/family/family-type/getAll?${params || ""}`
    )
);
/* table Meta Data */

export const getAllFamilyUnits = createAsyncThunk(
  `${sliceName}/getAllFamilyUnits`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/family/family-unit/getAll`)
);

export const getFamilyUnitById = createAsyncThunk(
  `${sliceName}/getFamilyUnitById`,
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/family/family-unit/get/${id}?filter=`
    )
);

export const addFamilyUnit = createAsyncThunk(
  `${sliceName}/addFamilyUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/family-unit/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteFamilyUnit = createAsyncThunk(
  `${sliceName}/deleteFamilyUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/family-unit/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

//FamilyType
/* table Meta Data */
export const getFamilyUnitsMetaData = createAsyncThunk(
  `${sliceName}/getFamilyUnitsMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/family/family-unit/getMetadata`)
);

export const getFamilyUnitsRowData = createAsyncThunk(
  `${sliceName}/getFamilyUnitsRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/family/family-unit/getAll?${params || ""}`
    )
);


export const getFamilyTypeById = createAsyncThunk(
  `${sliceName}/getFamilyTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/family/family-type/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addFamilyType = createAsyncThunk(
  `${sliceName}/addFamilyType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/family-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const addFamilyTypeWrapper = createAsyncThunk(
  `${sliceName}/addFamilyTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/family-type/wrapper-family-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteFamilyType = createAsyncThunk(
  `${sliceName}/deleteFamilyType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/family-type/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);


//Family Property
/* table Meta Data */
export const getFamilyPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getFamilyPropertiesMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/family/family-property/getMetadata`
    )
);

export const getFamilyPropertiesRowData = createAsyncThunk(
  `${sliceName}/getFamilyPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/family/family-property/getAll?${params || ""}`
    )
);
/* table Meta Data */

export const getAllFamilyProperties = createAsyncThunk(
  `${sliceName}/getAllFamilyProperties`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/family/family-property/getAll`)
);

export const getFamilyPropertyById = createAsyncThunk(
  `${sliceName}/getFamilyPropertyById`,
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/family/family-property/get/${id}?filter[include][][relation]=familyPropertyValues`
    )
);

export const addFamilyPropertyWrapper = createAsyncThunk(
  `${sliceName}/addFamilyPropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/family-property/wrapper-family-properties/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);


export const deleteFamilyProperty = createAsyncThunk(
  `${sliceName}/deleteFamilyProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/family-property/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

// Family
/* table Meta Data */
export const getFamilyMetaData = createAsyncThunk(
  `${sliceName}/getFamilyMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/family/getMetadata`)
);

export const getFamilyMetaDataByComponenetTypeId = createAsyncThunk(
  `${sliceName}/getFamilyMetaDataByComponenetTypeId`,
  async () => await fetchWrapper.get(`${baseUrl}/family/getMetadata`)
);

export const getFamilyTagByFamilyId = createAsyncThunk(
  `${sliceName}/getFamilyTagByFamilyId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/family/family-type/${id}/all-tags/get`)
);

export const getFamilyRowData = createAsyncThunk(
  `${sliceName}/getFamilyRowData`,
  async (params) => {
    const extraURL = params ? '?' + params : ''
    return await fetchWrapper.get(`${baseUrl}/family/getAll${extraURL}`)
  }
);

/* table Meta Data */
export const getAllFamily = createAsyncThunk(
  `${sliceName}/getAllFamily`,
  async () => await fetchWrapper.get(`${baseUrl}/family/getAll`)
);

export const getFamilyById = createAsyncThunk(
  `${sliceName}/getFamilyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/family/get/${id}?filter[include][][relation]=familyPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addFamily = createAsyncThunk(
  `${sliceName}/addFamily`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/family/add`, values);
    return res || res === "" ? res : null;
  }
);
export const addFamilyWrapper = createAsyncThunk(
  `${sliceName}/addFamilyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/family/wrapper-family/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteFamily = createAsyncThunk(
  `${sliceName}/deleteFamily`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/family/delete${deletePermanent ? '-permanent' : ''}/${id}`, {
      id,
    });
    return res;
  }
);

//getFamilyPropertiesByFamilyTypeId
export const getFamilyPropertiesByFamilyTypeId = createAsyncThunk(
  `${sliceName}/getFamilyPropertiesByFamilyTypeId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/family/family-type/get/${id}`)
);

//getFamilyPropertiesByFamilyTypeId
export const getStonePropertiesByStoneTypeId = createAsyncThunk(
  `${sliceName}/getStonePropertiesBySotneTypeId`,
  async (id) => await fetchWrapper.get(`${baseUrl}/stone/stone-type/get/${id}`)
);

// Duplicate Actions
export const duplicateFamily = createAsyncThunk(
  `${sliceName}/duplicateFamily`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/family/duplicate/${id}`)
);

export const duplicateFamilyUnit = createAsyncThunk(
  `${sliceName}/duplicateFamilyUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/family/family-unit/duplicate/${id}`)
);

export const duplicateFamilyType = createAsyncThunk(
  `${sliceName}/duplicateFamilyType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/family/family-type/duplicate/${id}`)
);

export const duplicateFamilyProperty = createAsyncThunk(
  `${sliceName}/duplicateFamilyProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/family/family-property/duplicate/${id}`)
);

export const getFamilyMetaDataByFamilyIdForVersion = createAsyncThunk(
  `${sliceName}/getFamilyMetaDataByFamilyIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/family/getMetadata?versioning=true&${params || ""}`)
);

export const getFamilyTypesMetaDataByFamilyIdForVersion = createAsyncThunk(
  `${sliceName}/getFamilyTypesMetaDataByFamilyIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/family/family-type/getMetadata?versioning=true&${params || ""}`)
);

export const getFamilyPropertiesMetaDataByFamilyIdForVersion = createAsyncThunk(
  `${sliceName}/getFamilyPropertiesMetaDataByFamilyIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/family/family-property/getMetadata?versioning=true&${params || ""}`)
);

export const getFamilyUnitsMetaDataByFamilyIdForVersion = createAsyncThunk(
  `${sliceName}/getFamilyUnitsMetaDataByFamilyIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/family/family-unit/getMetadata?versioning=true&${params || ""}`)
);

export const getFamilyRowDataByFamilyId = createAsyncThunk(
  `${sliceName}/getFamilyRowDataByFamilyId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/family/version-history/get/${id}?${params || ""}`)
);

export const getFamilyTypesRowDataByFamilyId = createAsyncThunk(
  `${sliceName}/getFamilyTypesRowDataByFamilyId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/family/family-type/version-history/get/${id}?${params || ""}`)
);

export const getFamilyPropertiesRowDataByFamilyId = createAsyncThunk(
  `${sliceName}/getFamilyPropertiesRowDataByFamilyId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/family/family-property/version-history/get/${id}?${params || ""}`)
);

export const getFamilyUnitsRowDataByFamilyId = createAsyncThunk(
  `${sliceName}/getFamilyUnitsRowDataByFamilyId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/family/family-unit/version-history/get/${id}?${params || ""}`)
);

// slice
export const familySlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default familySlice.reducer;
