/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

//Components
import { Button } from "@mui/material";

// Custom Component
import ActionDialog from "_components/ActionDialog";
import InnerLoader from "_components/InnerLoader";
import ApiErrorMsg from "_components/ApiErrorMsg";


// Lodash
import { isEqual } from "lodash";

// Store
import {
  getFamilyMetaDataByFamilyIdForVersion,
  getFamilyRowDataByFamilyId,
  deleteFamily,
  duplicateFamily,
} from "_store/pim/family.slice";
import { createAlert } from "_store/alert.slice";

//utils
import {
  generateUpdateApiQueryParams,
  formattedErrorResponse,
  uniqueMetaDataColumns,
  getPageSize,
} from "_utils/commonFunctions";
import { getNestedTableFormattedData } from "_utils/tableDataFormatted";


const AdvanceTable  = React.lazy(()=> import("_components/AdvanceTable"));

// old state holder
let tableOldState = {}
const familyTypeIDCol = {
  "keyName": "familyTypeId",
  "headerLabel": "familyTypeId",
  "filterable": {
    "type": "text"
  },
  "sortable": true,
  "visible": false,
  "keymapping": {},
  "datakey": {},
  "translatable": false,
  "static": true,
  "id": "familyTypeId",
  "columnMapping": "",
  "nestedLevel": "",
  "linkable": false,
  "linkType": "",
  "statusCode": "rowStatusCode",
  "hasNestedColumns": false
}

const FamilysVersionHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { id, action } = useParams();

  /* For tables */
  const [metaData, setMetaData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [queryParams, setQueryParams] = useState(getPageSize());
  const [dataLoading, setDataLoading] = useState(true);
  const [errorLoading, seErrorLoading] = useState(null);
  const [advanceTableData, setAdvanceTableData] = useState(null);
  const refs = useRef(true);
  const [loading, setLoading] = useState(true);
  const advancedTable = useRef();
  const [searchParams] = useSearchParams();
  const label = searchParams.get("label") ? searchParams.get("label") : "";
  const isDeleted = action === 'deleted' ?? location.pathname.includes('/deleted') ?? false;
  const familyType = searchParams.get("typeId") ? searchParams.get("typeId") : "";

  // props for action buttons in family properties table
  const tablePropertyActionProps = () => {
    const actionArray = []
      if (isDeleted) {
      actionArray.push({
        actionType: "content_copy",
        actionHandler: (props) => handleClick("duplicate", props?.cell?.row?.original)
      })
        actionArray.push({
          actionType: "visibility",
          actionHandler: (props) => handleClick("view", props?.cell?.row?.original)
        })
      } else {
        actionArray.push({
          actionType: "edit",
          actionHandler: (props) => handleClick("edit", props?.cell?.row?.original)
        })
      }
      return actionArray
  }

  /* Load Meta Data on load */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const firstRender = refs.current;
    if (firstRender) {
      refs.current = false;
      loadTableMetaData();
      if(location?.state?.queryParams){
        setQueryParams(location?.state?.queryParams)
      }
    }
  });

  // ? side effect start______________________
  // only trigger update table api
  const stateHandler = async (tableState, reload = false) => {
    const newTableState = JSON.parse(JSON.stringify(tableState))
    if (!isEqual(tableOldState, newTableState)) {
      setLoading(true);
      tableOldState = newTableState;
      const params = { ...newTableState, familyTypeId: familyType }
      let queryParamsData = generateUpdateApiQueryParams(params);
      setQueryParams(queryParamsData)
      loadTableRowData(queryParamsData)
    }
  };

  /* Load Meta Data */
  const loadTableMetaData = (id, tags = []) => {
    let queryParamsData = generateUpdateApiQueryParams({ familyTypeId: familyType })
    dispatch(getFamilyMetaDataByFamilyIdForVersion(queryParamsData)).then((response) => {
      if (response?.payload) {
        let pageSizeValue = localStorage.getItem('pageSize');
        pageSizeValue =  Number(pageSizeValue);
        const metaDataParams = { familyTypeId: id, pageSize: pageSizeValue ?? 10, pageIndex: 0 }
        let queryParamsDataLoadTable = generateUpdateApiQueryParams(metaDataParams)
        if (tags.length > 0) {
          queryParamsDataLoadTable += '&tagIds={"id":["' + tags.join('","') + '"]}'
        }
        response.payload.columns = [...(response?.payload?.columns || []), familyTypeIDCol]
        setMetaData(uniqueMetaDataColumns(response?.payload)); /*  fix to avoid column duplicates */
        loadTableRowData(queryParamsDataLoadTable);
      }
      else {
        seErrorLoading(formattedErrorResponse(response?.error))
        setDataLoading(false);
      }
    }).catch(error => { setDataLoading(false); });
  };

  /* Load Row Data */
  const loadTableRowData = (params) => {
    seErrorLoading(null)
    dispatch(getFamilyRowDataByFamilyId({params: params, id: id})).then((response) => {
      if (response?.payload) {
        let rawRowData = response?.payload;
        rawRowData.pageCount = (rawRowData?.pageCount) ? rawRowData.pageCount : 1;
        rawRowData.pageIndex = (rawRowData?.pageIndex) ? (rawRowData.pageIndex) : 0;
        rawRowData.pageSize = (rawRowData?.pageSize !== 0) ? (rawRowData.pageSize) : 10;
        rawRowData.filters = ((rawRowData?.filters !== 0) ? (rawRowData.filters) : []) || [];
        rawRowData.sortBy = ((rawRowData?.sortBy !== 0) ? (rawRowData.sortBy) : []) || [];
        setRowData(rawRowData);
      }
      else {
        setRowData([]);
        setAdvanceTableData({
          ...rowData,
          data: [],
        });
        seErrorLoading(formattedErrorResponse(response?.error))
        setDataLoading(false);
      }
    });
  };

  // Load data
  useEffect(() => {
    if (Array.isArray(metaData?.columns) && Array.isArray(rowData?.data)) {
      loadData(rowData, metaData);
    }
  }, [metaData, rowData]);

  // pass the query params as value in arguments
  const loadData = (rowData, metaData) => {
    const data = getNestedTableFormattedData(rowData?.data, metaData?.columns);
    setLoading(false);
    setAdvanceTableData({
      ...rowData,
      data
    });
  };

  useEffect(() => {
    if (familyType) {
      loadTableMetaData(familyType)
    }
  }, [familyType, dispatch])

  // finish Loading and table state updates
  useEffect(() => {
    if (Array.isArray(metaData?.columns) && Array.isArray(advanceTableData?.data) && dataLoading) {
      setTimeout(() => setDataLoading(false), 1000);
      setShowTable(true);
      setLoading(false);
      if(advancedTable?.current){advancedTable.current.fireFetchData()}
    }
  }, [advanceTableData, dataLoading, metaData?.columns]);
  // ? side effect ends_______________________
  /* For tables ends*/

  const handleClick = (actionType, value) => {
    const pathEdit = `./../../${value.id}/edit?typeId=${value?.familyTypeId}&listPage=versionHistory&versionHistoryId=${id}&label=${label}`;
    const pathView = `./../../../${value.id}/view?typeId=${value?.familyTypeId}&listPage=versionHistoryView&versionHistoryId=${id}&label=${label}`;

    if (actionType === 'edit') {
      navigate(pathEdit);
    } else if (actionType === 'view') {
      navigate(pathView);
    } else if (actionType === 'duplicate') {
      handleOpenModal("Do you want to duplicate the item", "Duplicate Metal", "info", "Duplicate", { "id": value.id })
    } else {
      handleOpenModal("Do you want to delete the Family", "Delete Family", "error", "Delete", { "id": value.id })
    }
  };

  // Modal
  const [modalState, setModalState] = useState({ description: "", openModal: false, title: "", actionType: "", actionButton: "" });
  const handleOpenModal = (description, title, action, actionButton, params) => {
    setModalState({ description, openModal: true, title, actionType: action, actionButton, params: params });
  };

  const handleCloseModal = () => {
    setModalState({ openModal: false });
  };

  const handleModalAction = (params) => {
    if (params.action === 'Delete') {
      params.deletePermanent = isDeleted
      return dispatch(deleteFamily(params)).then((res) => {
        if (!res.error) {
          dispatch(createAlert({ alertType: "success", msg: "Family Deleted successfully", timeout: 5000 }));
          setModalState({ openModal: false })
          loadTableRowData(queryParams)//table update
        }
      })
        .catch(error => console.log('error'));
    }
    else if (params.action === 'Duplicate') {
      return dispatch(duplicateFamily(params)).then((res) => {
        if (!res.error) {
          dispatch(createAlert({ alertType: "success", msg: "Family Duplicated successfully", timeout: 5000 }));
          setModalState({ openModal: false })
          loadTableRowData(queryParams)//table update
        }
      })
        .catch(() => console.log('error'));
    }
  };

  // create new Modal
  const handleCreateNew = () => {
    navigate(`./../../add?typeId=${familyType}&label=${label}&listPage=versionHistory&versionHistoryId=${id}`)

  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="headerTitleRow">
          <h1>{ label } - {isDeleted && 'Deleted '} Version History</h1>
          <span>
            <Button type="button" onClick={() => navigate({ pathname: isDeleted ? "../deleted" : "..", search: `?typeId=${familyType}` }, { state: location?.state })} variant="default" className="mr-1">
              <span className="mr-1 material-icons">chevron_left</span> Back to
              Listing
            </Button>
            {
              !isDeleted &&
              <Button type="button" onClick={handleCreateNew} variant="contained" color="secondary"><span className="mr-1 material-icons">add</span> Create New</Button>
            }
          </span>
        </div>

        <div className="table-container">
          {dataLoading && <InnerLoader />}
          {showTable && (
            <Suspense fallback={<InnerLoader />}>
              <AdvanceTable
                data={advanceTableData}
              metaData={metaData}
              loading={loading}
              actionProps={tablePropertyActionProps()}
              stateHandler={stateHandler}
              onFetchData={() => {
                // console.log('fetchData')
}}
/> 
</Suspense>
          )}
          {errorLoading && (
            <ApiErrorMsg error={errorLoading?.error} />
          )}
        </div>
        <ActionDialog {...modalState} onCloseModal={handleCloseModal} onAction={handleModalAction} />
      </div>
    </div>

  );
};

export default FamilysVersionHistory;
