import * as React from 'react';

const ApiErrorMsg = ({error}) => {
  return (
    <div className="alert alert-danger d-flex align-items-center">
      <span className='material-icons mr-2'>error_outline</span>
      <div>{error}</div>
    </div>
  );
}

export default ApiErrorMsg;
