import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// Lodash
import { Chip } from '@mui/material';

const AutocompleteForFormik = ({ form, value, field, label, readOnly = false, disabled = false, disableClearable = false, ...props }) => {
  const { name } = field;
  const { setFieldValue, errors, touched, setTouched } = form;
  return (
    <Autocomplete
      {...props}
      multiple={Array.isArray(value)}
      filterSelectedOptions
      name={name}
      disableClearable={disableClearable}
      id={`${name}-label`}
      value={value}
      options={props.options || []}
      noOptionsText="Nothing selected"
      getOptionLabel={(option) => (option?.label) || String(value)}
      onBlur={() => setTouched({ [name]: true })}
      isOptionEqualToValue={(option, value) => option?.value === value}
      onChange={(event, newValue) => {
        let updatedValue;
        if (Array.isArray(newValue)) {
          updatedValue = [];
          newValue.forEach(item => (updatedValue.push(item?.value ?? item)))
        }
        else {
          updatedValue = newValue && newValue.value ? newValue.value : newValue || '';
        }
        setFieldValue(name, updatedValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onBlur={props?.handleBlur}
          required={props?.required}
          placeholder={(value && Array.isArray(value) && value?.length > 0) ? (readOnly || disabled) ? '' : 'Add More' : 'Select'}
          error={Boolean(touched[name] && errors[name])}
          helperText={Boolean(errors[name] && touched[name]) && errors[name]}
          disabled={disabled}
        />
      )}
      disabled={disabled}
      renderTags={() => {
        return value.map((tag) => (
          <Chip disabled={readOnly} key={tag} className='mr-1' label={props.options?.filter(item => item.value === tag)?.[0]?.label} onDelete={() => {
            setFieldValue(name, value.filter(val => val !== tag))
            if(props.onDelete) props.onDelete(tag)

          }} />
        ));
      }}
      readOnly={readOnly}
    />
  );
}

export default AutocompleteForFormik;