import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "addonData";

// APIs
/*addOn Units */
/* table Meta Data */
export const getAddOnUnitsMetaData = createAsyncThunk(
  `${sliceName}/getAddOnUnitsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/addon/addon-unit/getMetadata`)
);

export const getAddOnUnitsRowData = createAsyncThunk(
  `${sliceName}/getAddOnUnitsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/addon/addon-unit/getAll?${params || ""}`)
);
/* table Meta Data */

export const getAlladdOnUnits = createAsyncThunk(
  `${sliceName}/getAlladdOnUnits`,
  async () => await fetchWrapper.get(`${baseUrl}/addon/addon-unit/getAll`)
);

export const getAddOnUnitById = createAsyncThunk(
  `${sliceName}/getAddOnUnitById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/addon/addon-unit/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addaddOnUnit = createAsyncThunk(
  `${sliceName}/addaddOnUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/addon-unit/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteAddOnUnit = createAsyncThunk(
  `${sliceName}/deleteAddOnUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/addon-unit/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

//addOnType
/* table Meta Data */
export const getAddOnTypesMetaData = createAsyncThunk(
  `${sliceName}/getAddOnTypesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/addon/addon-type/getMetadata`)
);

export const getAddOnTypesRowData = createAsyncThunk(
  `${sliceName}/getAddOnTypesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/addon/addon-type/getAll?${params || ""}`)
);

export const getAddOnTypesList = createAsyncThunk(
  `${sliceName}/getAddOnTypesList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/addon/addon-type/dropdown/getAll?${params || ""}`)
);

/* table Meta Data */

export const getAddOnTypeById = createAsyncThunk(
  `${sliceName}/getAddOnTypeByIdWrapper`,
  async (id) => await fetchWrapper.get(`${baseUrl}/addon/addon-type/get/${id}`)
);

export const getAddOnTypeByIdWrapper = createAsyncThunk(
  `${sliceName}/getAddOnTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/addon/addon-type/get/${id}?filter[include][][relation]=allowedPropertiesValueMap${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addaddOnType = createAsyncThunk(
  `${sliceName}/addaddOnType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/addon-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const addaddOnTypeWrapper = createAsyncThunk(
  `${sliceName}/addaddOnTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/addon-type/wrapper-addon-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteAddOnType = createAsyncThunk(
  `${sliceName}/deleteAddOnType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/addon-type/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

//addOn Property
/* table Meta Data */
export const getAddOnPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getAddOnPropertiesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/addon/addon-property/getMetadata`)
);

export const getAddOnPropertiesRowData = createAsyncThunk(
  `${sliceName}/getAddOnPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/addon/addon-property/getAll?${params || ""}`)
);
/* table Meta Data */

export const getAlladdOnProperties = createAsyncThunk(
  `${sliceName}/getAlladdOnProperties`,
  async () => await fetchWrapper.get(`${baseUrl}/addon/addon-property/getAll`)
);

export const getAddOnPropertyById = createAsyncThunk(
  `${sliceName}/getAddOnPropertyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/addon/addon-property/get/${id}?filter[include][][relation]=addonPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addaddOnPropertyWrapper = createAsyncThunk(
  `${sliceName}/addaddOnPropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/addon-property/wrapper-addon-property/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteAddOnProperty = createAsyncThunk(
  `${sliceName}/deleteAddOnProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/addon-property/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);


// addOn
/* table Meta Data */
export const getAddOnMetaData = createAsyncThunk(
  `${sliceName}/getAddOnMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/addon/getMetadata`)
);

export const getAddOnMetaDataByAddOnId = createAsyncThunk(
  `${sliceName}/getAddOnMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/addon/getMetadata?${params || ""}`)
);

export const getAddOnTagByAddOnId = createAsyncThunk(
  `${sliceName}/getAddOnTagByAddOnId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/addon/addon-type/${id}/all-tags/get`)
);

export const getAddOnRowData = createAsyncThunk(
  `${sliceName}/getAddOnRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/addon/getAll?${params || ""}`)
);
/* table Meta Data */
export const getAddOnById = createAsyncThunk(
  `${sliceName}/getAddOnById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/addon/get/${id}?filter[include][][relation]=addonPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addaddOn = createAsyncThunk(
  `${sliceName}/addaddOn`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/addon/add`, values);
    return res || res === "" ? res : null;
  }
);
export const addaddOnWrapper = createAsyncThunk(
  `${sliceName}/addaddOnWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/wrapper-addon/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editaddOn = createAsyncThunk(
  `${sliceName}/editaddOn`,
  async (values) => {
    let id = values.id;
    let updatedData = values;
    delete updatedData.id;
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/update/${id}`,
      updatedData
    );
    return res || res === "" ? values : null;
  }
);

export const deleteAddOn = createAsyncThunk(
  `${sliceName}/deleteAddOn`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/addon/delete${deletePermanent ? "-permanent" : ""}/${id}`, {
      id,
    });
    return res;
  }
);

//getAddOnPropertiesByaddOnTypeId
export const getAddOnPropertiesByaddOnTypeId = createAsyncThunk(
  `${sliceName}/getAddOnPropertiesByaddOnTypeId`,
  async (id) => await fetchWrapper.get(`${baseUrl}/addon/addon-type/get/${id}`)
);


// Duplicate Actions
export const duplicateAddOn = createAsyncThunk(
  `${sliceName}/duplicateAddOn`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/addon/duplicate/${id}`)
);

export const duplicateAddOnUnit = createAsyncThunk(
  `${sliceName}/duplicateAddOnUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/addon/addon-unit/duplicate/${id}`)
);

export const duplicateAddOnType = createAsyncThunk(
  `${sliceName}/duplicateAddOnType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/addon/addon-type/duplicate/${id}`)
);

export const duplicateAddOnProperty = createAsyncThunk(
  `${sliceName}/duplicateAddOnProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/addon/addon-property/duplicate/${id}`)
);

// version history
export const getAddOnMetaDataByAddOnIdForVersion = createAsyncThunk(
  `${sliceName}/getAddOnMetaDataForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/addon/getMetadata?versioning=true&${params || ""}`)
);

export const getAddOnTypesMetaDataByAddOnIdForVersion = createAsyncThunk(
  `${sliceName}/getAddOnTypesMetaDataByAddOnIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/addon/addon-type/getMetadata?versioning=true&${params || ""}`)
);

export const getAddOnPropertiesMetaDataByAddOnIdForVersion = createAsyncThunk(
  `${sliceName}/getAddOnPropertiesMetaDataByAddOnIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/addon/addon-property/getMetadata?versioning=true&${params || ""}`)
);

export const getAddOnUnitsMetaDataByAddOnIdForVersion = createAsyncThunk(
  `${sliceName}/getAddOnUnitsMetaDataByAddOnIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/addon/addon-unit/getMetadata?versioning=true&${params || ""}`)
);

export const getAddOnRowDataByAddOnId = createAsyncThunk(
  `${sliceName}/getAddOnRowDataByAddOnId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/addon/version-history/get/${id}?${params || ""}`)
);

export const getAddOnTypesRowDataByAddOnId = createAsyncThunk(
  `${sliceName}/getAddOnTypesRowDataByAddOnId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/addon/addon-type/version-history/get/${id}?${params || ""}`)
);

export const getAddOnPropertiesRowDataByAddOnId = createAsyncThunk(
  `${sliceName}/getAddOnRowDataForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/addon/addon-property/version-history/get/${id}?${params || ""}`)
);

export const getAddOnUnitsRowDataByAddOnId = createAsyncThunk(
  `${sliceName}/getAddOnUnitsRowDataByAddOnId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/addon/addon-unit/version-history/get/${id}?${params || ""}`)
);

export const addAddonBulkUpdates = createAsyncThunk(
  `${sliceName}/addAddonBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/addon/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);


// slice
export const addOnSlice = createSlice({
  name: sliceName,
  initialState: {},
});


export default addOnSlice.reducer;
