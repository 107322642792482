import React, { useRef, useState } from "react"
import { Divider, IconButton, Tooltip } from "@mui/material"
import ActionDialog from "_components/ActionDialog";

const BG_EFFECT_CLASS = "bg_effect";

const defaultModalState = {
  description: "",
  openModal: false,
  title: "",
  actionType: "",
  actionButton: "",
}

const BlockHeading = ({
  onRemove,
  index = 0,
  isShowIndex,
  dragHandleProps,
  onMoveElement,
  isLast,
  onToggle,
  open,
  title = "",
  onDuplicate,
  isDuplicate,
  children,
  disabled = false,
  width = "4%",
  readOnly = false
}) => {
  const divRef = useRef();
  const isMoveable = typeof onMoveElement === "function";

  const [modalState, setModalState] = useState(defaultModalState);
  const handleCloseModal = () => {
    setModalState(defaultModalState);
  }

  const handleOpenModal = (
    description,
    title,
    action,
    actionButton,
    params
  ) => {
    setModalState({
      description,
      openModal: true,
      title,
      actionType: action,
      actionButton,
      params: params,
    });
  };

  const handleRemoveConfirm = () => {
    handleOpenModal("Do you want to delete this?", "Delete", "error", "Delete", { "type": "delete" })
  }

  const handleModalAction = ({ type }) => {
    if (type === "delete") {
      onRemove();
      handleCloseModal();
    }
  }

  const handleBgEffect = () => {

    if (divRef.current) {
      divRef.current.classList.add(BG_EFFECT_CLASS);

      setTimeout(() => {
        divRef.current.classList.remove(BG_EFFECT_CLASS);
      }, 800);
    }
  }

  const handleMoveUp = () => {
    onMoveElement("up");
    handleBgEffect();
  }

  const handleMoveDown = () => {
    onMoveElement("down");
    handleBgEffect()
  }

  return (
    <div className="page_route_header" data-index={`${index + 1}`} ref={divRef}>
      <Divider mb={1} />
      <div className="d-flex justify-content-between py-2">
        <div className="collpase_btn d-flex" style={{ width: '85%' }}>
          <div className="mt-3 mr-3" style={{ width: open ? '4%' : width }}>
            {
              dragHandleProps &&
              <span
                className="mr-1"
                {...dragHandleProps}
              >
                <span className="material-icons mr-1">
                  drag_handle
                </span>
              </span>
            }

            {
              typeof onToggle === "function" &&
              <IconButton
                sx={{ mr: 1 }}
                disabled={disabled}
                onClick={() => onToggle(index)}
              >
                {
                  open ?
                    <span className="material-icons mr-1">
                      remove_circle_outline
                    </span> :
                    <span class="material-icons">
                      add_circle_outline
                    </span>
                }
              </IconButton>
            }
            {
              isShowIndex &&
              <span className="index_label">#{index + 1}</span>
            }
            {
              <>
                <span className="index_label">#{index + 1}&nbsp;&nbsp;</span>
                {(title && !open) ? <span className="index_label">{title ?? ""} </span> : null}
              </>
            }
          </div>
          <div style={{ flex: '1' }}>
            {children}
          </div>
        </div>

        {!disabled &&
          <div>
            {
              isMoveable &&
              <>
                <Tooltip title="Move up">
                  <span>
                    <IconButton onClick={handleMoveUp} disabled={index === 0 || readOnly}>
                      <span className="material-icons">
                        arrow_circle_up
                      </span>
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title="Move down">
                  <span>
                    <IconButton onClick={handleMoveDown} disabled={isLast || readOnly}>
                      <span className="material-icons">
                        arrow_circle_down
                      </span>
                    </IconButton>
                  </span>
                </Tooltip>

              </>
            }
            {
              isDuplicate &&
              <IconButton
                color="primary"
                onClick={onDuplicate}
              >
                <span className="material-icons">content_copy</span>
              </IconButton>
            }
            {
              onRemove &&
              <IconButton onClick={handleRemoveConfirm} color="error">
                <span className="material-icons">
                  delete_outline
                </span>
              </IconButton>
            }
          </div>
        }
      </div>

      <ActionDialog
        {...modalState}
        onCloseModal={handleCloseModal}
        onAction={handleModalAction}
      />
    </div >
  );
}

export default BlockHeading;
