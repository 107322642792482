import { createSlice } from '@reduxjs/toolkit';

export const AlertSlice = createSlice({
  name: "alertData",
  initialState: {
        alertData: {},
        isAlertShown: false
    },
  reducers: {
    createAlert: (state, action) => {
        return {
            ...state,
            alertData: {alertType: action.payload.alertType, msg: action.payload.msg, timeout:action.payload.timeout},
            isAlertShown: true
      };
    }
  }
});

export const { createAlert } = AlertSlice.actions;

export default AlertSlice.reducer;