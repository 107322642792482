import React from "react";
import {
	Stack,
	Box,
	Checkbox,
	Divider,
} from "@mui/material";

const CheckItem = ({
	data,
	idx,
	parentIndex = [],
	onToggleCheck
}) => {

	const children = data?.children || [];
	const handleToggle = () => {
		onToggleCheck([...parentIndex, idx], !data.isChecked);
	}
	const isChecked = !!data?.isChecked;
	const isCheckedIndeterminate = isChecked && children.length ? children.some(i => !i.isChecked) : false;
	const name = data?.name || "";
	
	return (
		<Box>
			<Stack
				justifyContent={"space-between"}
				alignItems="center"
				flexDirection={"row"}
			>
				<label>
					<Checkbox	
						onChange={handleToggle}
						checked={isChecked}
						indeterminate={isCheckedIndeterminate}
					/>

					{name}
				</label>
			</Stack>
			{
				children?.length ?
					<Box sx={{ marginLeft: "20px" }}>
						<Divider />
						{
							children.map((item, index) =>
								<CheckItem
									key={item?.id}
									idx={index}
									parentIndex={[...parentIndex, idx]}
									data={item}
									onToggleCheck={onToggleCheck}
								/>
							)
						}
					</Box> : ""
			}

		</Box>
	);
}

export default CheckItem;
